import { AEngine } from './core/AEngine.js';
// BackOffice Initializer
import * as CentralVerification from './centralverification-initializer-legacy.js';
// Runnables
import { ALoadBackground } from './runnables/ALoadBackground.js';
// Services
import { AAdminAlertsService } from './services/AAdminAlertsService.js';
import { AAlertService } from './services/AAlertService.js';
import { AEventService } from './services/AEventService.js';
import { AFilterService } from './services/AFilterService.js';
import { AInterpolateService } from './services/AInterpolateService.js';
import { AJsonService } from './services/AJsonService.js';
import { AMenuHelperService } from './services/AMenuHelperService.js';
import { AMenuService } from './services/AMenuService.js';
import { APermissionService } from './services/APermissionService.js';
import { APolicyService } from './services/APolicyService.js';
import { APreferenceService } from './services/APreferenceService.js';
import { ARequestService } from './services/ARequestService.js';
import { ARouteDrawingService } from './services/ARouteDrawingService.js';
import { ATranslateService } from './services/ATranslateService.js';
import { AVerificationService } from './services/AVerificationService.js';
import { createEventHandlers } from './centralverification-initializer-legacy.js';
import { ADetectionService } from './services/ADetectionService.js';
import { AUserActionService } from './services/AUserActionService.js';
import { ALoadingService } from './services/ALoadingService.js';
import { AGlobalService } from './services/AGlobalService.js';
import { ADependencyService } from './services/ADependencyService.js';
import { initPolicies } from './policies.js';
import { ALoadHotReload } from './runnables/ALoadHotReload.js';
import { APurgatoryService } from './services/APurgatoryService.js';
import { AAutoRefreshService } from './services/AAutoRefreshService.js';
import { ARouteService } from './services/ARouteService.js';
import { AStateService } from './services/AStateService.js';
import { ACentralVerificationService } from './services/ACentralVerificationService.js';
import { AGeoService } from './core/maps/AGeoService.js';
import { ACoreMapService } from './core/maps/ACoreMapService.js';
import { AGeoPaintService } from './core/maps/AGeoPaintService.js';
import { AQueueService } from './services/AQueueService.js';
import { AChannelService } from './services/AChannelService.js';
import { AIdAllocatorService } from './core/allocator/AIdAllocatorService.js';
import { AMapOverlayService } from './core/maps/AMapOverlayService.js';
import { ANodeSessionService } from './services/ANodeSessionService.js';
async function run() {
    const services = [
        ACoreMapService,
        AGeoPaintService,
        AGeoService,
        ADependencyService,
        AGlobalService,
        ARouteService,
        ALoadingService,
        AEventService,
        AMenuService,
        AAlertService,
        ATranslateService,
        ARequestService,
        APreferenceService,
        ARouteDrawingService,
        AVerificationService,
        AJsonService,
        AAdminAlertsService,
        AMapOverlayService,
        ANodeSessionService,
        APolicyService,
        AMenuHelperService,
        AFilterService,
        AInterpolateService,
        APermissionService,
        ADetectionService,
        AUserActionService,
        APurgatoryService,
        AAutoRefreshService,
        AStateService,
        ACentralVerificationService,
        AChannelService,
        AQueueService,
        AIdAllocatorService,
    ];
    const runnables = [
        ALoadBackground,
        ALoadHotReload
    ];
    await AEngine.executeServices(services);
    await AEngine.autoInitServices();
    await AEngine.executeRunnables(runnables);
    initPolicies();
    createEventHandlers();
}
window.addEventListener('load', async () => {
    try {
        await run();
        await CentralVerification.run();
    }
    catch (err) {
        console.error(err);
    }
});

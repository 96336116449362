import { EVENTS } from "../services/AEventService.js";
export const defaultOptions = {
    useCentralServerLogging: true,
    useAdminAlerts: true,
    useConsoleLog: true,
    useModal: true,
    displayModalOnce: true,
    err: []
};
export var ERROR_GROUPS;
(function (ERROR_GROUPS) {
    ERROR_GROUPS["TreeBreakdownError"] = "TreeBreakdownError";
    ERROR_GROUPS["InvalidDetectionStatisticsCategory"] = "InvalidDetectionStatisticsCategory";
    ERROR_GROUPS["MissingUnifications"] = "MissingUnifications";
    ERROR_GROUPS["ModalError"] = "ModalError";
    ERROR_GROUPS["CalcLegendColorError"] = "CalcLegendColorError";
    ERROR_GROUPS["CalcMarkerColorError"] = "CalcMarkerColorError";
})(ERROR_GROUPS || (ERROR_GROUPS = {}));
export class AError {
    constructor() {
        throw new Error(`You're not supposed to make an instance of the AError class.`);
    }
    static set stackTraceLimit(value) {
        // @ts-ignore
        Error.stackTraceLimit = value;
    }
    static get stackTraceLimit() {
        // @ts-ignore
        return Error.stackTraceLimit;
    }
    static sendLogStreamToCentralServer(data) {
        return requestService.sendLog(data);
    }
    static getInputType(input) {
        return (typeof input !== 'string') ? ((input instanceof Error) ? 'err' : (input instanceof Array ? 'arr' : 'obj')) : 'str';
    }
    static formatError(opt, input) {
        switch (AError.getInputType(input)) {
            case 'arr': break;
            case 'str':
                Object.assign(opt, { err: [new Error(input)] });
                break;
            case 'err':
                Object.assign(opt, { err: [input] });
                break;
            case 'obj':
                switch (AError.getInputType(input.err)) {
                    case "str":
                        input.err = new Error(input.err);
                        break;
                    case "arr":
                        input.err = input.err.map(v => v instanceof Error ? v : new Error(v));
                        break;
                }
                Object.assign(opt, input);
                break;
        }
        return opt;
    }
    static handleSilent(err, adminAlertGroup) {
        try {
            let opt = Object.assign({}, defaultOptions, adminAlertGroup ? { adminAlertGroup } : {});
            AError.formatError(opt, err);
            opt.useModal = false;
            return AError.handleInternal(opt);
        }
        catch (err) {
            Events.tryInvoke(EVENTS.ERROR, err);
            console.error(err);
            return Promise.resolve();
        }
    }
    static handle(input) {
        try {
            const inputObj = typeof input !== 'string' && Object.keys(input).length > 0 ? input : { err: input };
            let opt = Object.assign({}, defaultOptions, inputObj);
            return AError.handleInternal(AError.formatError(opt, opt.err));
        }
        catch (err) {
            Events.tryInvoke(EVENTS.ERROR, err);
            console.error(err);
            return Promise.resolve();
        }
    }
    static shouldShowModal({ err, timestamp }) {
        const MODAL_TIMEOUT = 4000;
        if (Alerts.isPreviousErr()) {
            return false;
        }
        // if (window.hasOwnProperty('errorDialog')) {
        //   const errorDialog = globalThis.errorDialog
        //   const prevErrorTimestamp = errorDialog.timestamp
        //   const prevError = errorDialog.err
        //   if (err.toString() === prevError.toString()) {
        //     if (timestamp - prevErrorTimestamp <= MODAL_TIMEOUT) {
        //       // Same error within 1s
        //       return false
        //     }
        //   }
        // }
        return true;
    }
    static saveModalToCache({ err, timestamp }) {
        Object.assign(window, {
            errorDialog: {
                err,
                timestamp
            }
        });
    }
    static async handleInternal({ err, useCentralServerLogging, useAdminAlerts, adminAlertGroup, useConsoleLog, useModal, displayModalOnce }) {
        Events.tryInvoke(EVENTS.ERROR, err[0]);
        if (useModal) {
            const timestamp = Date.now();
            if (displayModalOnce) {
                if (AError.shouldShowModal({ err, timestamp })) {
                    Alerts.somethingWentWrong();
                }
                AError.saveModalToCache({ err, timestamp });
            }
            else {
                Alerts.somethingWentWrong();
            }
        }
        if (useCentralServerLogging) {
            if (!Array.isArray(err)) {
                err = [err];
            }
            err.map(e => AError.sendLogStreamToCentralServer({
                Level: "Warning",
                Message: AError.simplifyError(e)
            }));
        }
        // if (useAdminAlerts) {
        //   if (!Array.isArray(err)) {
        //     err = [err as Error]
        //   }
        //   err.map(e => globalThis.adminAlertsService?.createErrorAlert(e, adminAlertGroup))
        // }
        if (useConsoleLog) {
            if (!Array.isArray(err)) {
                err = [err];
            }
            err.map(e => console.error(e));
        }
        Loading.reset();
        globalThis.filterService?.setActive(true);
    }
    static getMetaData(err) {
        const trim = (str) => str.replace(/\s\s+/g, ' ').trim();
        const [firstline, ...lines] = err.stack.split('\n');
        const cleanStack = [firstline].concat(lines.filter(line => (line.indexOf('AError.js') === -1))).map(line => trim(line));
        return {
            err: err,
            cleanStack: cleanStack.join('\n')
        };
    }
    // ================= Error Signature Logic ================= 
    static simplifyError(err, separator = '\n') {
        const [head, ...tail] = err.stack.split('\n');
        let cleanStack = tail.map(line => {
            AError.errorStripMethods.map(method => line = method.apply(null, [line]));
            return line.split(':');
        });
        AError.errorGroupMethods.map(method => cleanStack = method.apply(null, [cleanStack]));
        return [head].concat(cleanStack).join(separator);
    }
    static get errorStripMethods() {
        return [
            // stripFunctionName
            (line) => (line.indexOf('(http') !== -1) ? 'at ' + line.slice(1, -1) : line,
            // stripPrefix
            (line) => (line.indexOf('<anonymous>') !== -1) ? line.substring(line.indexOf('<anonymous>')) : line.split('/').pop(),
            // stripLineWidth
            (line) => (line.lastIndexOf(':') !== -1) ? line.substring(0, line.lastIndexOf(':')) : line
        ];
    }
    static get errorGroupMethods() {
        return [
            // groupLineNumbers
            (stackTail, a = []) => {
                stackTail.map((l, i) => i > 0 ?
                    ((a[a.length - 1][0] === l[0]) ? a[a.length - 1][1].push(l[1]) : a.push([l[0], [l[1]]])) : a.push([l[0], [l[1]]]));
                return a;
            },
            // formatLineNumbers
            (stackTail) => {
                return stackTail.map(([first, linenrs]) => [first].concat(`[${linenrs.join(' ')}]`).join(' '));
            }
        ];
    }
}
// @ts-ignore
Error.stackTraceLimit = 100;

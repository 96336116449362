import { AEngine } from "../core/AEngine.js";
import { AEventableValue } from "../core/eventable/AEventableValue.js";
import { ALERT_TITLES, ALERT_BUTTONS } from "./AAlertService.js";
import { EVENTS } from "./AEventService.js";
// import { VerifyRequests } from "../pages/cvs/genk_messages.js"
export class ACentralVerificationService {
    constructor() {
        // private fakeQueue: AVerifyRequest[] = [ ...VerifyRequests ]
        this.queue = [];
        this.verdicts = new AEventableValue({ initialValue: [] });
        this.verdictObservable = new AEventableValue({});
        if (window.cvs === undefined) {
            Object.assign(globalThis, {
                cvs: this
            });
        }
    }
    autoInit() {
        Events.hardwire('VerifyRequest', (detection) => {
            this.queue.push(detection);
            Events.tryInvoke('VerifyRequestPrepared', detection);
        });
        // if (this.fakeQueue.length > 0) Events.tryInvoke('VerifyRequest', this.fakeQueue.shift())
    }
    subscribeToVerifyRequest(cbFn) {
        Events.on('VerifyRequestPrepared', cbFn);
    }
    sendVerdict(verdict, context) {
        const verdictWithContext = { ...verdict, ...context };
        // this.setPreviousDetection(verdictWithContext, context)
        this.verdicts.value.push(verdictWithContext);
        this.verdictObservable.add(verdictWithContext);
        this.verdicts.reload();
        // if (this.fakeQueue.length > 0) Events.tryInvoke('VerifyRequest', this.fakeQueue.shift())
        // AEngine.warn(`// TODO: Re-enable cvs.sendVerdict`)
        CCCClient.SendMessage('VerifyResponse', 1, verdict);
    }
    async detectionChangeRegimeCode(selected, params) {
        const promise = Loading.waitForEvent('ChangeRegimeResponse');
        CCCClient.SendMessage("ChangeRegimeRequest", 1, {
            DetectionId: selected.DetectionId,
            DetectionDeviceId: selected.DetectionDeviceId,
            RegimeCode: params.RegimeCode
        });
        // const cfgRegimes: {[OffenceCode: string]: } = Config.Regimes
        const newData = await promise;
        if (newData.MustFollowUp === true) {
            cvs.queue.unshift(Object.assign({}, selected, newData));
        }
        else {
            Alerts.show({
                title: ALERT_TITLES.Success,
                buttons: ALERT_BUTTONS.ok,
                content: await Translate.get('The detection has been moved to another channel!'),
            });
        }
    }
    async detectionChangeOffenceCode(selected, params) {
        const promise = Loading.waitForEvent('ChangeOffenceResponse');
        CCCClient.SendMessage("ChangeOffenceRequest", 1, {
            DetectionId: selected.DetectionId,
            DetectionDeviceId: selected.DetectionDeviceId,
            OffenceCode: params.OffenceCode
        });
        const newData = await promise;
        if (newData.MustFollowUp === true) {
            cvs.queue.unshift(Object.assign({}, selected, newData));
        }
        else {
            Alerts.show({
                title: ALERT_TITLES.Success,
                buttons: ALERT_BUTTONS.ok,
                content: await Translate.get('The detection has been moved to another channel!'),
            });
        }
    }
    async detectionPermitRecheck(selected) {
        const updatedDetectionPromise = Loading.waitForEvent(EVENTS.PARKING_RIGHT_STREAM);
        CCCClient.SendMessage("ParkingRightRequest", 1, { DetectionId: selected.DetectionId, DetectionDeviceId: selected.DetectionDeviceId });
        const newData = await Loading.waitForPromises(updatedDetectionPromise);
        await this.handleParkingRightStream({
            selected,
            newData
        });
    }
    async detectionChangeRequest(selected, params) {
        const updatedDetectionPromise = Loading.waitForEvent(EVENTS.PARKING_RIGHT_STREAM);
        CCCClient.SendMessage("DetectionChangeRequest", 1, params);
        const newData = await updatedDetectionPromise;
        await this.handleParkingRightStream({
            selected,
            newData
        });
    }
    async handleParkingRightStream(opt) {
        if (AEngine.isDevelopmentMode) {
            AEngine.log('data', { old: opt.selected, new: opt.newData });
        }
        // TODO: Jaap says that the Object.assign is unnecessary.
        const updatedDetection = Object.assign({}, opt.selected, opt.newData);
        // If detection has parking right then no verification is needed
        if (updatedDetection.MustFollowUp === true) {
            cvs.queue.unshift(updatedDetection);
        }
        else {
            Alerts.show({
                title: ALERT_TITLES.Success,
                buttons: ALERT_BUTTONS.ok,
                content: await Translate.get('No further action is needed for this detection!'),
            });
        }
    }
    // This cancels fine & sends it to the cancellation channel
    // The teamleader will process this detection using VerificationResult & VerificationResultText
    cancelFine(data) {
        requestService.send("CancelFine", data);
    }
}

import { _getEle$, checkIfFullScreen } from "../../utils/tools.js";
export function getCenterAny(markerOrPolygon) {
    if (markerOrPolygon instanceof google.maps.Polyline) {
        const path = markerOrPolygon.getPath();
        markerOrPolygon = path.getAt(Math.floor(path.getLength() / 2));
    }
    if (markerOrPolygon instanceof google.maps.Polygon) {
        return getPolygonCenter(markerOrPolygon);
    }
    if (markerOrPolygon instanceof google.maps.Marker) {
        return markerOrPolygon.getPosition();
    }
    if (markerOrPolygon instanceof google.maps.LatLng) {
        return markerOrPolygon;
    }
    if (markerOrPolygon.pos) {
        return markerOrPolygon.pos;
    }
    if (markerOrPolygon.position) {
        return markerOrPolygon.position;
    }
    console.warn('Marker/Polygon: ', markerOrPolygon);
    throw new Error('Unexpected marker/polygon');
}
globalThis.getCenterAny = getCenterAny;
export function getPolygonCenter(polygon) {
    const path = polygon.getPath();
    const length = path.getLength();
    let bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < length; i++) {
        let val = path.getAt(i);
        bounds.extend(val);
    }
    return bounds.getCenter();
}
// export function toggleMapFullScreen(ele: string | Element | JQuery): boolean {
export function toggleMapFullScreen(opt) {
    // let element = _getEle(ele)
    const isfull = checkIfFullScreen();
    if (isfull) {
        exitFullScreen();
    }
    else {
        requestFullScreen(opt.mapElement);
    }
    return isfull;
}
function requestFullScreen(ele) {
    if (ele.requestFullscreen) {
        ele.requestFullscreen();
    }
    else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
    }
    else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
    }
    else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
    }
}
function exitFullScreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
        // @ts-ignore
    }
    else if (document.msExitFullscreen) {
        // @ts-ignore
        document.msExitFullscreen();
        // @ts-ignore
    }
    else if (document.mozCancelFullScreen) {
        // @ts-ignore
        document.mozCancelFullScreen();
        // @ts-ignore
    }
    else if (document.webkitExitFullscreen) {
        // @ts-ignore
        document.webkitExitFullscreen();
    }
    else {
        alert("No ExitFullScreen function found!");
    }
}
export function toggleMapSearch(opt) {
    let $map = _getEle$(opt.ele);
    const $search = $map.find('.map-overlay-search');
    const isHidden = ($search.length === 0);
    if (isHidden) {
        // Show search bar
        const $topLeft = $map.find('.map-overlay-controls.top-left');
        const $topRight = $map.find('.map-overlay-controls.top-right');
        const width = ($topRight.position().left) - ($topLeft.position().left + $topLeft.width()) - 15;
        const $ele = $(`
      <div class="map-overlay-search">
        <input type="text" class="form-input" />
        <div class="autocomplete-list hidden">
        </div>
      </div>
    `);
        const AUTOCOMPLETE_THRESHOLD = 3;
        const $input = $ele.find('input');
        const $autoComplete = $ele.find('.autocomplete-list');
        $input.on('click', (e) => {
            $autoComplete.toggleClass('hidden', $input.val().length < AUTOCOMPLETE_THRESHOLD || $autoComplete.children().length === 0);
            // $input.trigger('keyup')
        });
        $input.on('keydown', (e) => {
            console.log('keydown', e.keyCode);
            switch (e.keyCode) {
                case 13: // RETURN OR ENTER
                    var $selected = $autoComplete.find('.selected');
                    if ($selected.length > 0) {
                        $input.val($selected.text());
                        console.log({ select: `"${$selected.text()}"` });
                        $selected.trigger('click');
                    }
                    break;
                case 27: // ESCAPE
                    if (!$autoComplete.is('.hidden')) {
                        $autoComplete.addClass('hidden');
                    }
                    break;
                case 35: // END
                    changeMapSearchSelected({ $autoComplete, increment: 1000, fallbackIndex: $autoComplete.children().length - 1 });
                    break;
                case 36: // HOME
                    changeMapSearchSelected({ $autoComplete, increment: -1000, fallbackIndex: 0 });
                    break;
                case 38: // ARROW UP
                    changeMapSearchSelected({ $autoComplete, increment: -1, fallbackIndex: $autoComplete.children().length - 1 });
                    break;
                case 40: // ARROW DOWN
                    changeMapSearchSelected({ $autoComplete, increment: 1, fallbackIndex: 0 });
                    break;
            }
        });
        $input.on('keyup', (e) => {
            console.log('keyup', e.keyCode);
            switch (e.keyCode) {
                case 13: // RETURN OR ENTER
                case 27: // ESCAPE
                    e.preventDefault();
                    break;
                case 35: // END
                case 36: // HOME
                case 38: // ARROW UP
                case 40: // ARROW DOWN
                    e.preventDefault();
                    if ($autoComplete.is('.hidden')) {
                        $autoComplete.removeClass('hidden');
                    }
                    break;
                default:
                    $autoComplete.toggleClass('hidden', $input.val().length < AUTOCOMPLETE_THRESHOLD);
                    if (($input.val() + '').length >= AUTOCOMPLETE_THRESHOLD) {
                        // refreshMapSearchSuggestions({ ...opt, $input, $autoComplete }).catch(AError.handle)
                    }
                    break;
            }
        });
        $ele.css({
            left: ($topLeft.position().left + $topLeft.width() + 8) + 'px',
            width: width + 'px'
        });
        $map.append($ele);
        $input.trigger('focus');
    }
    else {
        // Hide search bar
        $search.remove();
    }
    return isHidden;
}
function changeMapSearchSelected(opt) {
    const { $autoComplete, increment, fallbackIndex } = opt;
    const $selected = $autoComplete.find('.selected');
    if ($selected.length > 0) {
        $selected.removeClass('selected');
        var $neighbour = $autoComplete.children().eq($selected.index() + increment);
        if ($neighbour.length > 0) {
            $neighbour.addClass('selected');
        }
        else {
            $autoComplete.children().eq(fallbackIndex).addClass('selected');
        }
    }
    const $newSelected = $autoComplete.find('.selected');
    if ($newSelected.length > 0) {
        $autoComplete.scrollTop($newSelected.offset().top + $autoComplete.scrollTop() - ($autoComplete.height() / 1.2));
    }
}

import { AError, ERROR_GROUPS } from "../../classes/AError.js";
import { EVENTS } from "../../services/AEventService.js";
import { AColor } from "../colors/AColor.js";
import { _getEle, asyncMapArray, convertObjectToArray } from "../../utils/tools.js";
import { AEngine, sleep } from "../AEngine.js";
import { ALL_GEO_MAPPING, ALL_GEO_TYPES, ALL_MAP_OPTIONS, densityOptions, MAP_OPTIONS, MAP_POSITION, mapStyleOptions, UNLOAD_OPTIONS } from "./AMapStructs.js";
import { AGeoUtils } from "./AGeoUtils.js";
import { AMapOverlayService } from "./AMapOverlayService.js";
import { toggleMapFullScreen, toggleMapSearch } from "./mapToolsTmp.js";
import { ALERT_TITLES, ALERTS, ALERT_BUTTONS, ALERT_STATUS } from "../../services/AAlertService.js";
import { AIdAllocator } from "../allocator/AIdAllocator.js";
import { AGeoService } from "./AGeoService.js";
import { APrefs } from "../../services/APreferenceService.js";
import { AIsLatLngValid } from "../../utils/maps.js";
import { polygons_tableformatter } from "../../format/table_formatter.js";
import { APopoverService } from "../../services/APopoverService.js";
import { toast } from "../../utils/toasts.js";
import { AFormInstance } from "../form/AFormInstance.js";
Object.assign(globalThis, {
    MAP_OPTIONS,
    ALL_MAP_OPTIONS,
});
export function DefaultBounds() {
    return new google.maps.LatLng(0, 0);
}
export function createMap(id, options) {
    const opts = $.extend(true, {}, options);
    const mapEle = _getEle(id);
    if (!mapEle) {
        throw new Error(`Couldn't find HTMLElement: #${id}`);
    }
    const map = new google.maps.Map(mapEle, $.extend(true, {
        _geoInstances: {},
        _geoObjectsVisible: {},
        fullscreenControl: false,
        panControl: false,
        streetViewControl: false,
        streetViewControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
        scaleControl: true,
        scaleControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
        mapTypeControl: false,
        mapTypeControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
    }, opts));
    $(map.getDiv()).data('map', map);
    return map;
}
// type ASessionMap = { [NodeName: string]: AMarker }
// type ADisplaySessionOnMapOptions = { lerpSpeed?: number } & { interpolate: boolean, sessions: ASessionMap }
// type ADisplaySessionOnMapOptionsPrivate = { lerpSpeed: number, map: any } & { interpolate: boolean, sessions: ASessionMap }
export class ACoreMapService {
    get geoObjects() {
        return AEngine.get(AGeoService).geoObjects;
    }
    constructor() {
        this.scaleOpts = {};
        this.requested = {};
        // this.cache = {} as any // new Map<MAP_OPTIONS, {}>()
        // this.geoObjects = {} as any // new Map<MAP_OPTIONS, Map<number, GeoObject>>()
        this.lastArea = null;
        globalThis.mapHelperService = this;
    }
    autoInit() {
        this.mapOverlayService = AEngine.get(AMapOverlayService);
        Events.hardwire(EVENTS.PAGE_INITIALIZED, () => {
            this.refreshGeoLayerState();
        });
        Events.h_once(EVENTS.PAGE_INITIALIZED, () => {
            coreMapService.initMapRightClick();
        });
    }
    areGeoInstancesCached(map, input) {
        const k = ALL_GEO_TYPES.includes(input) ? input : this.mapOptionToGeoType(input);
        // return ((this.cache[k]?.length ?? 0) > 0) // .filter(v => v != null)
        return (map._geoInstances[k]?.length ?? 0) > 0;
    }
    getGeoInstancesOnMap(map, input) {
        const k = ALL_GEO_TYPES.includes(input) ? input : this.mapOptionToGeoType(input);
        return (map._geoInstances[k] || []).filter(v => v != null);
    }
    geoObjectMapper(mapOption) {
        return this.geoObjects[this.mapOptionToGeoType(mapOption)].GeoMap;
    }
    /**
     * Move polygons from one map to another
     * @param {any} map
     * @param {any} polygons
     */
    redirectPolygons(map, polygons) {
        polygons.map(p => {
            p.setMap(map);
            p.setOptions({ visible: true });
        });
    }
    fetchStreetData(coordinates) {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'location': coordinates }, function (results, status) {
                if (status !== 'OK') {
                    return reject(status);
                }
                return resolve(results);
            });
        });
    }
    fetchStreetPosition(service, coordinates) {
        return new Promise((resolve, reject) => {
            try {
                service.getPanoramaByLocation(coordinates, 50, (res, status) => (status === 'OK') ? resolve(res.location.latLng) : resolve(false));
            }
            catch (err) {
                reject(err);
            }
        });
    }
    geoPointToCoords(data, srid = 4326) {
        //data = {"type": "Point", "coordinates": [10.750851301946682, 59.908290312191056]}
        // PageScript.map.setCenter(new google.maps.LatLng(59.9267065136985, 10.8019512068214))
        if (srid == 4326) {
            const [lng, lat] = data.coordinates;
            return { lat, lng };
        }
        else {
            const [lat, lng] = data.coordinates;
            return { lat, lng };
        }
    }
    geoJsonToPolygonCoords(data, srid = 4326) {
        const { coordinates } = data;
        let center = { lat: 0, lng: 0 };
        let areaPaths = [];
        for (let r = 0; r < coordinates.length; r++) {
            let ring = [];
            for (let p = 0; p < coordinates[r].length; p++) {
                let latlng;
                if (srid == 4326) {
                    latlng = { lat: coordinates[r][p][1], lng: coordinates[r][p][0] };
                }
                else {
                    latlng = { lat: coordinates[r][p][0], lng: coordinates[r][p][1] };
                }
                if (r == 0) {
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                }
                ring.push(latlng);
            }
            if (r == 0) {
                center.lat /= coordinates[r].length;
                center.lng /= coordinates[r].length;
            }
            areaPaths.push(ring);
        }
        if (areaPaths.length == 1) {
            areaPaths = areaPaths[0];
        }
        return {
            coordinates: areaPaths,
            center: center
        };
    }
    get_legend_from_name(name, useOpacity) {
        switch (name) {
            case "green": return this.get_legend_green(useOpacity);
            case "yellow": return this.get_legend_yellow(useOpacity);
            case "orange": return this.get_legend_orange(useOpacity);
            case "red": return this.get_legend_red(useOpacity);
            case "blue": return this.get_legend_blue(useOpacity);
            case "grey": return this.get_legend_grey(useOpacity);
            case "brown": return this.get_legend_brown(useOpacity);
            case "white": return this.get_legend_white(useOpacity);
            case "black": return this.get_legend_black(useOpacity);
        }
        return null;
    }
    get_legend_green(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 255, 0).rgba(0.7) : new AColor(0, 255, 0).hexi,
            stroke: new AColor(5, 163, 0).hexi
        };
    }
    get_legend_orange(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 133, 0).rgba(0.7) : new AColor(255, 133, 0).hexi,
            stroke: new AColor(256, 128, 0).hexi
        };
    }
    get_legend_red(useOpacity) {
        return {
            fill: useOpacity ? new AColor(247, 0, 0).rgba(0.7) : new AColor(247, 0, 0).hexi,
            stroke: new AColor(185, 0, 0).hexi
        };
    }
    get_legend_grey(useOpacity) {
        // TODO: Implementation
        return {
            fill: useOpacity ? new AColor(128, 128, 128).rgba(0.7) : new AColor(128, 128, 128).hexi,
            stroke: new AColor(128, 128, 128).hexi
        };
    }
    get_legend_blue(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 173, 255).rgba(0.7) : new AColor(0, 173, 255).hexi,
            stroke: new AColor(0, 114, 232).hexi
        };
    }
    get_legend_yellow(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 255, 0).rgba(0.7) : new AColor(255, 255, 0).hexi,
            stroke: new AColor(255, 255, 0).hexi
        };
    }
    get_legend_brown(useOpacity) {
        return {
            fill: useOpacity ? new AColor(200, 100, 0).rgba(0.7) : new AColor(200, 100, 0).hexi,
            stroke: new AColor(200, 100, 0).hexi
        };
    }
    get_legend_white(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 255, 255).rgba(0.7) : new AColor(255, 255, 255).hexi,
            stroke: new AColor(255, 255, 255).hexi
        };
    }
    get_legend_black(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 0, 0).rgba(0.7) : new AColor(0, 0, 0).hexi,
            stroke: new AColor(0, 0, 0).hexi
        };
    }
    get_legend_brown_outline() {
        return {
            fill: new AColor(200, 100, 0).rgba(0.0),
            stroke: new AColor(200, 100, 0).hexi
        };
    }
    get_legend_spread(useOpacity) {
        return [
            {
                fill: useOpacity ? new AColor(27, 247, 20).rgba(0.7) : new AColor(27, 247, 20).hexi,
                stroke: new AColor(27, 247, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(20, 247, 222).rgba(0.7) : new AColor(20, 247, 222).hexi,
                stroke: new AColor(20, 247, 222).hexi
            },
            {
                fill: useOpacity ? new AColor(31, 20, 247).rgba(0.7) : new AColor(31, 20, 247).hexi,
                stroke: new AColor(31, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(135, 20, 247).rgba(0.7) : new AColor(135, 20, 247).hexi,
                stroke: new AColor(135, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(240, 20, 247).rgba(0.7) : new AColor(240, 20, 247).hexi,
                stroke: new AColor(240, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 20, 20).rgba(0.7) : new AColor(247, 20, 20).hexi,
                stroke: new AColor(247, 20, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 153, 20).rgba(0.7) : new AColor(247, 153, 20).hexi,
                stroke: new AColor(247, 153, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 243, 20).rgba(0.7) : new AColor(247, 243, 20).hexi,
                stroke: new AColor(247, 243, 20).hexi
            }
        ];
    }
    get legend_legacy() {
        const { legendItems } = {
            legendItems: {
                'Default': this.get_legend_green(true),
                'No Parking Right': this.get_legend_red(true),
                'Illegally Parked': this.get_legend_brown_outline(),
                'Unknown Parking Right': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ IsIllegallyParked, HasParkingRight }) => {
                const { FillColor, StrokeColor } = this.getColors({ IsIllegallyParked, HasParkingRight });
                return {
                    fill: FillColor,
                    stroke: StrokeColor
                };
            },
            legendItems
        };
    }
    get legend_digital() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'Digital': this.get_legend_green(false),
                'NotDigital': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                'Digital': this.get_legend_green(true),
                'NotDigital': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ Digital }) => {
                if (Digital === null)
                    return null;
                switch (Digital) {
                    case 'InProgress': return calcTemplate.InProgress;
                    case 'Digital': return calcTemplate.Digital;
                    case 'NotDigital': return calcTemplate.NotDigital;
                    case 'NotProcessed': return calcTemplate.NotProcessed;
                }
            },
            legendItems
        };
    }
    get legend_illegallyparked() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'NotIllegallyParked': this.get_legend_green(false),
                'IllegallyParked': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                'NotIllegallyParked': this.get_legend_green(true),
                'IllegallyParked': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ IllegallyParked }) => {
                if (IllegallyParked === null)
                    return null;
                switch (IllegallyParked) {
                    case 'NotIllegallyParked': return calcTemplate.NotIllegallyParked;
                    case 'InProgress': return calcTemplate.InProgress;
                    case 'NotProcessed': return calcTemplate.NotProcessed;
                }
                if (IllegallyParked?.startsWith('IllegallyParked')) {
                    return calcTemplate.IllegallyParked;
                }
            },
            legendItems
        };
    }
    get legend_parkingright() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'ParkingRight': this.get_legend_green(false),
                'NoParkingRight': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'Other': this.get_legend_grey(false)
            },
            legendItems: {
                'ParkingRight': this.get_legend_green(true),
                'NoParkingRight': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'Other': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ ParkingRight }) => {
                if (ParkingRight === null)
                    return null;
                switch (ParkingRight) {
                    case 'InProgress': return calcTemplate.InProgress;
                    case 'NoParkingRight': return calcTemplate.NoParkingRight;
                }
                if (ParkingRight?.startsWith('NoParkingRightNeeded')) {
                    return calcTemplate.Other;
                }
                if (ParkingRight?.startsWith('ParkingRight')) {
                    return calcTemplate.ParkingRight;
                }
                if (ParkingRight?.startsWith('Indecisive')) {
                    return calcTemplate.NoParkingRight;
                }
                if (ParkingRight?.startsWith('NotProcessed')) {
                    return calcTemplate.Other;
                }
            },
            legendItems
        };
    }
    get legend_verification() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'NoVerificationNeeded': this.get_legend_green(false),
                'FinedIllegallyParked': this.get_legend_red(false),
                'FinedNoParkingRight': this.get_legend_blue(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                // TODO: Remove NoVerificationNeeded (Bespreek met Jaap ivm met limit 2000 scans, moet ik de query aanpassen ofz?)
                'NoVerificationNeeded': this.get_legend_green(true),
                'FinedIllegallyParked': this.get_legend_red(true),
                'FinedNoParkingRight': this.get_legend_blue(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ Verification }) => {
                if (Verification === null)
                    return null;
                if (['NoVerificationNeeded', 'NotFined'].includes(Verification)) {
                    return calcTemplate.NoVerificationNeeded;
                }
                if (['Fined_IllegallyParked', 'Fined_TimeLimitedParkingExperired'].includes(Verification)) {
                    return calcTemplate.FinedIllegallyParked;
                }
                if (['Fined_NoParkingRight', 'Fined_Unknown'].includes(Verification)) {
                    return calcTemplate.FinedNoParkingRight;
                }
                if (Verification?.startsWith('InProgress')) {
                    return calcTemplate.InProgress;
                }
                if (Verification?.startsWith('NotProcessed')) {
                    return calcTemplate.NotProcessed;
                }
            },
            legendItems
        };
    }
    get legend_detection_state() {
        const colorSpread = this.get_legend_spread(false);
        const colorSpreadOpacity = this.get_legend_spread(true);
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'GeoQueue': colorSpread.pop(),
                'PrdbQueue': colorSpread.pop(),
                'PdaVerificationQueue': colorSpread.pop(),
                'CentralVerificationQueue': colorSpread.pop(),
                'AssignedToPda': colorSpread.pop(),
                'AssignedToCentralVerification': colorSpread.pop(),
                'Unknown': colorSpread.pop(),
                'Done': colorSpread.pop()
            },
            legendItems: {
                'GeoQueue': colorSpreadOpacity.pop(),
                'PrdbQueue': colorSpreadOpacity.pop(),
                'PdaVerificationQueue': colorSpreadOpacity.pop(),
                'CentralVerificationQueue': colorSpreadOpacity.pop(),
                'AssignedToPda': colorSpreadOpacity.pop(),
                'AssignedToCentralVerification': colorSpreadOpacity.pop(),
                'Unknown': colorSpreadOpacity.pop(),
                'Done': colorSpreadOpacity.pop()
            }
        };
        return {
            calcColor: ({ DetectionState }) => {
                if (DetectionState === null)
                    return null;
                switch (DetectionState) {
                    case 'InProgress_GeoQueue': return calcTemplate.GeoQueue;
                    case 'InProgress_PrdbQueue': return calcTemplate.PrdbQueue;
                    case 'InProgress_PdaVerificationQueue': return calcTemplate.PdaVerificationQueue;
                    case 'InProgress_CentralVerificationQueue': return calcTemplate.CentralVerificationQueue;
                    case 'InProgress_AssignedToPda': return calcTemplate.AssignedToPda;
                    case 'InProgress_AssignedToCentralVerification': return calcTemplate.AssignedToCentralVerification;
                    case 'InProgress_Unknown': return calcTemplate.Unknown;
                    case 'Done': return calcTemplate.Done;
                }
            },
            legendItems
        };
    }
    set calcLegendColor(val) {
        throw new Error(`Not Implemented Yet!`);
    }
    get calcLegendColor() {
        const fallbackClr = { fill: '#000', stroke: '#000' };
        const calcColor = PageScript.__map_legend_calcColor;
        if (calcColor == undefined) {
            AError.handleSilent(`Page calcLegendColor has not been initialized!`);
            return (_ => (fallbackClr));
        }
        const resultFunc = (args) => {
            const clr = calcColor(args);
            if (clr === null) {
                AError.handle({
                    err: new Error(`AMapHelperService.calcLegendColor unification is null data=${JSON.stringify(args)}`),
                    useAdminAlerts: false,
                    useCentralServerLogging: false,
                    useModal: false,
                });
            }
            else if (clr === undefined) {
                AError.handleSilent(`AMapHelperService.calcLegendColor unexpected unification (POSSIBLY MISSING UNIFICATIONS) data=${JSON.stringify(args)}`, ERROR_GROUPS.CalcLegendColorError);
            }
            return clr || fallbackClr;
        };
        return resultFunc;
    }
    get tabDefinitions() {
        console.error(`// TODO: Remove hardcoded aspect`);
        const tabOptions = [
            'ParkingRight',
            'IllegallyParked',
            'Verification',
            'DetectionState',
            'Digital',
            'Default'
        ];
        const tabConfigurations = {
            'Digital': this.legend_digital,
            'IllegallyParked': this.legend_illegallyparked,
            'ParkingRight': this.legend_parkingright,
            'Verification': this.legend_verification,
            'DetectionState': this.legend_detection_state,
            'Default': this.legend_legacy
        };
        return { tabOptions, tabConfigurations };
    }
    async generateDetectionsLegendHtml({ legendItems }) {
        // const { tabOptions } = this.mapOverlayService.tabDefinitions
        // TODO: Fix hardcoded poop
        const { tabOptions } = this.tabDefinitions;
        const [legendLabelText, translations, tabOptionsT,] = await Promise.all([
            Translate.get('Legend'),
            Translate.get(Object.keys(/** @type {object} */ (legendItems))),
            Translate.get(tabOptions)
        ]);
        const LEGEND_SELECTION = this.legendSelection || tabOptions[0];
        const HTML_SELECT = tabOptions.map(tabOption => (`
            <option${LEGEND_SELECTION == tabOption ? ' selected="selected"' : ''} value="${tabOption}">${tabOptionsT[tabOption]}</option>
         `).trim()).join('\r\n');
        const HTML_LEGEND_ITEMS = Object.keys(legendItems).map(key => {
            const { stroke, fill } = legendItems[key];
            return (`
        <div>
          <div class="detectionPreview" style="background-color: ${fill}; outline-color: ${stroke}"></div>
          <span>${translations[key]}</span>
        </div>
      `).trim();
        }).join('\r\n');
        const style = (`zoom: ${globalThis.AConfig?.get(`drawing & colors.legendScale`, 1.2) ?? 1.2};`);
        return (`
      <div class="legend legend-opaque legend-detections" style="${style}">
        <div class="legend-label label-height-lg">${legendLabelText}</div>
        <select class="changeLegend">${HTML_SELECT}</select>
        ${HTML_LEGEND_ITEMS}
      </div>
    `);
    }
    async setDetectionsLegendDefault({ mapElement }) {
        const { tabConfigurations } = this.tabDefinitions;
        const view = tabConfigurations[this.legendSelection] || this.legend_parkingright;
        await Loading.waitForPromises(this.setDetectionsLegend(mapElement, view)).catch(AError.handle);
    }
    async setDetectionsLegend(mapElement, options) {
        const { calcColor } = options;
        const { tabConfigurations } = this.tabDefinitions;
        const $detectionsLegend = $(await this.generateDetectionsLegendHtml(options));
        const $select = $detectionsLegend.find('.changeLegend');
        $select.on('change', _ => {
            this.legendSelection = $select.val();
            Loading.waitForPromises(this.setDetectionsLegend(mapElement, tabConfigurations[$select.val()])).catch(AError.handle);
        });
        this.calcLegendColor = calcColor;
        this.mapOverlayService.add(mapElement, $detectionsLegend, MAP_POSITION.BOTTOM_LEFT, { uid: 'LEGEND_CONTROLS', order: 0 });
        await this.recolorAllMarkers({ calcColor });
    }
    async recolorAllMarkers(options) {
        const fallbackClr = { fill: '#000', stroke: '#000' };
        const calcColor = options.calcColor || this.calcLegendColor;
        const markers = this.fetchMarkers();
        await asyncMapArray(markers, 10, (marker) => {
            const optClr = calcColor(marker._final, { opacity: true });
            if (optClr === null) {
                AError.handle({
                    err: new Error(`AMapHelperService.recolorAllMarkers unification is null data=${JSON.stringify(marker._final)}`),
                    useAdminAlerts: false,
                    useCentralServerLogging: false,
                    useModal: false,
                });
            }
            else if (optClr === undefined) {
                AError.handleSilent(`AMapHelperService.recolorAllMarkers unexpected unification (POSSIBLY MISSING UNIFICATIONS) data=${JSON.stringify(marker._final)}`, ERROR_GROUPS.CalcMarkerColorError);
            }
            const clr = optClr || fallbackClr;
            const { fill, stroke } = (typeof clr === 'string') ? { fill: clr, stroke: clr } : clr;
            marker.setOptions({
                strokeColor: stroke,
                fillColor: fill
            });
        });
    }
    async createCountLabel() {
        const mapCountLabel = document.createElement('label');
        mapCountLabel.classList.add('map-control-count');
        const template = await Translate.get('Detections Displayed: 808');
        mapCountLabel.setAttribute('template', template);
        mapCountLabel.innerText = template.replace('808', '-');
        PageScript.map?.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(mapCountLabel);
    }
    updateCountLabel(count) {
        const $count = $('.map-control-count');
        if ($count.length) {
            const template = $count.attr('template') || '';
            const text = template.replace('808', count);
            $count.text(text);
        }
    }
    async createDownloadButton(options) {
        const { map, mapElement, order } = options;
        const $button = await menuService.addMapButton({
            map,
            mapElement,
            tag: 'a',
            title: 'Export kml',
            titleTag: 'span',
            icon: 'fa-solid fa-file-arrow-down',
            order,
            position: MAP_POSITION.TOP_RIGHT,
        });
        $button.attr('id', 'DownloadButton');
        $button.attr('disabled', 'disabled');
        return $button;
    }
    geoTypeToMapOption(geoType) {
        for (let opt in MAP_OPTIONS) {
            let isKeyNumber = Number(opt) >= 0;
            if (!isKeyNumber && opt === geoType) {
                return MAP_OPTIONS[opt];
            }
        }
        return MAP_OPTIONS.None;
    }
    mapOptionToGeoType(type) {
        for (let opt of ALL_MAP_OPTIONS) {
            if (opt & type) {
                return MAP_OPTIONS[type];
            }
        }
        throw new Error(`UnknownGeoType: ${type}`);
    }
    /**
     * @deprecated
     */
    showGeoObjectOnMap(map, area, geoType, geoObject, result, bounds, clickEvent) {
        const mapOption = this.geoTypeToMapOption(geoType);
        const colorConfig = this.getGeoObjectColor(mapOption, geoObject);
        const zIndex = this.getGeoObjectZIndex(mapOption);
        switch (geoObject.Geo.type) {
            case "Point": {
                const [lng, lat] = geoObject.Geo.coordinates;
                let mapsGeo = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, lng)
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        area: area,
                        scale: mapOption,
                        geoType: geoType
                    }
                });
                result.push(mapsGeo);
                mapsGeo.setMap(map);
                google.maps.event.addListener(mapsGeo, "click", clickEvent || this.onGeoClick);
                return;
            }
            case "LineString": {
                let parkingSpaceBounds = geoObject.Geo.coordinates;
                let path = [];
                let center = { lat: 0, lng: 0 };
                for (let p = 0; p < parkingSpaceBounds.length; p++) {
                    let latlng = {
                        lat: parkingSpaceBounds[p][1],
                        lng: parkingSpaceBounds[p][0]
                    };
                    if (bounds)
                        bounds.extend(latlng);
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                    path.push(latlng);
                }
                center.lat /= parkingSpaceBounds.length;
                center.lng /= parkingSpaceBounds.length;
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                let mapsGeo = new google.maps.Polyline({
                    path: path,
                    strokeColor: colorConfig.strokeColor,
                    strokeOpacity: colorConfig.strokeOpacity,
                    strokeWeight: 3,
                    // fillColor: colorConfig.fillColor,
                    // fillOpacity: colorConfig.fillOpacity,
                    zIndex: zIndex,
                    // position: center
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        area: area,
                        scale: mapOption,
                        geoType: geoType
                    }
                });
                geoObject.RefList.push(mapsGeo);
                result.push(mapsGeo);
                mapsGeo.setMap(map);
                google.maps.event.addListener(mapsGeo, "click", clickEvent || this.onGeoClick);
                return;
            }
            case "Polygon": {
                let parkingSpaceBounds = geoObject.Geo.coordinates;
                let parkingSpacePaths = [];
                let center = { lat: 0, lng: 0 };
                for (let r = 0; r < parkingSpaceBounds.length; r++) {
                    center = { lat: 0, lng: 0 };
                    let ring = [];
                    for (let p = 0; p < parkingSpaceBounds[r].length; p++) {
                        let latlng = {
                            lat: parkingSpaceBounds[r][p][1],
                            lng: parkingSpaceBounds[r][p][0]
                        };
                        if (bounds)
                            bounds.extend(latlng);
                        center.lat += latlng.lat;
                        center.lng += latlng.lng;
                        ring.push(latlng);
                    }
                    center.lat /= parkingSpaceBounds[r].length;
                    center.lng /= parkingSpaceBounds[r].length;
                    parkingSpacePaths.push(ring);
                }
                if (parkingSpacePaths.length == 1) {
                    parkingSpacePaths = parkingSpacePaths[0];
                }
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                let mapsGeo = new google.maps.Polygon({
                    paths: parkingSpacePaths,
                    strokeColor: colorConfig.strokeColor,
                    strokeOpacity: colorConfig.strokeOpacity,
                    strokeWeight: .5,
                    fillColor: colorConfig.fillColor,
                    fillOpacity: colorConfig.fillOpacity,
                    zIndex: zIndex,
                    // position: center
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        area: area,
                        scale: mapOption,
                        geoType: geoType
                    }
                });
                // Apply ref for fast lookup
                geoObject.RefList.push(mapsGeo);
                result.push(mapsGeo);
                mapsGeo.setMap(map);
                google.maps.event.addListener(mapsGeo, "click", clickEvent || this.onGeoClick);
                return;
            }
            case "MultiPolygon": {
                for (let g = 0; g < geoObject.Geo.coordinates.length; g++) {
                    let parkingSpaceBounds = geoObject.Geo.coordinates[g];
                    let parkingSpacePaths = [];
                    let center = { lat: 0, lng: 0 };
                    for (let r = 0; r < parkingSpaceBounds.length; r++) {
                        center = { lat: 0, lng: 0 };
                        let ring = [];
                        for (let p = 0; p < parkingSpaceBounds[r].length; p++) {
                            let latlng = {
                                lat: parkingSpaceBounds[r][p][1],
                                lng: parkingSpaceBounds[r][p][0]
                            };
                            if (bounds)
                                bounds.extend(latlng);
                            center.lat += latlng.lat;
                            center.lng += latlng.lng;
                            ring.push(latlng);
                        }
                        center.lat /= parkingSpaceBounds[r].length;
                        center.lng /= parkingSpaceBounds[r].length;
                        parkingSpacePaths.push(ring);
                    }
                    if (parkingSpacePaths.length == 1) {
                        parkingSpacePaths = parkingSpacePaths[0];
                    }
                    geoObject.Center = center;
                    // Construct the polygon, including both paths.
                    let mapsGeo = new google.maps.Polygon({
                        paths: parkingSpacePaths,
                        strokeColor: colorConfig.strokeColor,
                        strokeOpacity: colorConfig.strokeOpacity,
                        strokeWeight: .5,
                        fillColor: colorConfig.fillColor,
                        fillOpacity: colorConfig.fillOpacity,
                        zIndex: zIndex,
                        // position: center
                    });
                    Object.assign(mapsGeo, {
                        data: {
                            id: geoObject.Index,
                            area: area,
                            scale: mapOption,
                            geoType: geoType
                        }
                    });
                    // Apply ref for fast lookup
                    geoObject.RefList.push(mapsGeo);
                    result.push(mapsGeo);
                    mapsGeo.setMap(map);
                    google.maps.event.addListener(mapsGeo, "click", clickEvent || this.onGeoClick);
                }
                return;
            }
            case "Collection": /// ACCCServer incorrectly calls this Collection (http://gitserver/aci-software/ACCCServer/issues/28)
            case "GeometryCollection":
                let geometries = geoObject.Geo.geometries || geoObject.Geo.items; /// ACCCServer incorrectly calls this items  (http://gitserver/aci-software/ACCCServer/issues/28)
                for (let i = 0; i < geometries.length; i++) {
                    let newGeoObject = { Index: geoObject.Index, Geo: geometries[i], Name: geoObject.Name, Attributes: geoObject.Attributes, Created: geoObject.Created, Modified: geoObject.Modified, RefList: geoObject.RefList, Center: geoObject.Center };
                    this.showGeoObjectOnMap(map, area, geoType, newGeoObject, result, bounds, clickEvent);
                }
                return;
            default:
                throw new Error(`Unknown geo json type: ${geoObject.Geo.type}`);
        }
    }
    /**
     * @deprecated
     */
    async showGeoObjectsOnMap(geoType, map, bounds, clickEvent) {
        if (this.cache[geoType]) {
            if (clickEvent) {
                for (const polygon of this.cache[geoType] ?? []) {
                    google.maps.event.addListener(polygon, "click", clickEvent);
                }
            }
            this.redirectPolygons(map, this.cache[geoType] ?? []);
            return this.cache[geoType];
        }
        let result = [];
        await asyncMapArray(Object.keys(this.geoObjects[geoType] ?? []), 100, (id) => {
            return this.showGeoObjectOnMap(map, null, geoType, this.geoObjects[geoType][id], result, bounds, clickEvent);
        });
        this.cache[geoType] = result;
        return result;
    }
    /**
     * Creates a toggle button on the map to hide a category
     * @param bitmask only apply on specific type of polygons on the map
     * @param options options
     */
    async createMapToggleSettings(bitmask, { map, order, fitPolygons = true, showOneScale, click, enableExperimentalLoading }) {
        map._geoObjectsVisible = {};
        const t = await Translate.get(ALL_GEO_TYPES);
        let categories = ALL_GEO_MAPPING.map(({ geoType, mapOption }) => {
            map._geoObjectsVisible[geoType] = false;
            return (mapOption & bitmask) ? { geoType, mapOption, text: t[geoType] ?? '?', isCheckbox: true, enableExperimentalLoading } : null;
        }).filter(v => v != null);
        if (showOneScale === true) {
            const bounds = map.getBounds() || new google.maps.LatLngBounds();
            await Loading.waitForPromises(categories.map(async ({ geoType }) => {
                await this.showGeoObjectsOnMap.call(this, geoType, map, bounds, click);
            }));
            if (fitPolygons === true) {
                map.fit(bounds);
            }
        }
        const toggleTo = (geoType, $c) => this.toggleTo(map, geoType, click).finally(() => $c.map($c => $c.trigger('refreshstate')));
        const toggle = (geoType, $c) => this.toggle(map, geoType, click, !map._geoObjectsVisible[geoType])
            .finally(() => $c.map($c => $c.trigger('refreshstate')));
        const toggleAction = showOneScale ? toggleTo : toggle;
        const selectDefault = (showOneScale === true) ? ($c) => toggleAction('ParkingSpace', $c) : undefined;
        const elements = categories.map((c) => this.genMapPopoverItem(map, c));
        const $checkboxes = elements.map(e => e.$checkbox);
        elements.map(({ geoType, $checkbox }) => {
            $checkbox.on('change', _ => Loading.waitForPromises(toggleAction(geoType, $checkboxes)).catch(AError.handle));
        });
        if (selectDefault != null) {
            Loading.waitForPromises(selectDefault($checkboxes)).catch(AError.handle);
        }
        if (bitmask !== 0) {
            menuService.addMapDropdown(elements.map(e => e.$a), {
                map,
                mapElement: map.getDiv(),
                uid: 'scale-toggle',
                icon: 'fa-solid fa-draw-polygon',
                order,
                position: MAP_POSITION.TOP_LEFT
            });
        }
    }
    applyMapPreferences(map, opt) {
        let { mapStyle, landmarkDensity, labelDensity } = Object.assign({
            mapStyle: preferenceService.load(APrefs.MAP_STYLE, '0'),
            labelDensity: preferenceService.load(APrefs.MAP_LABEL_DENSITY, 'normal'),
            landmarkDensity: preferenceService.load(APrefs.MAP_LANDMARK_DENSITY, 'normal'),
        }, opt, map.get('lockPreferences'));
        const mapStyles = {};
        if ($(map.getDiv()).attr('quick-hide-labels')) {
            labelDensity = 'hidden';
        }
        if (mapStyle !== undefined) {
            this.applyMapStyler(Number(mapStyle), {
                mapStyles,
                allOptions: {
                    0: [],
                    1: [
                        { elementType: "geometry", stylers: [{ "color": "#f5f5f5" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#616161" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#f5f5f5" }] },
                        { featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ "color": "#bdbdbd" }] },
                        { featureType: "poi", elementType: "geometry", stylers: [{ "color": "#eeeeee" }] },
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { featureType: "poi.park", elementType: "geometry", stylers: [{ "color": "#e5e5e5" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#9e9e9e" }] },
                        { featureType: "road", elementType: "geometry", stylers: [{ "color": "#ffffff" }] },
                        { featureType: "road.arterial", elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#dadada" }] },
                        { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ "color": "#616161" }] },
                        { featureType: "road.local", elementType: "labels.text.fill", stylers: [{ "color": "#9e9e9e" }] },
                        { featureType: "transit.line", elementType: "geometry", stylers: [{ "color": "#e5e5e5" }] },
                        { featureType: "transit.station", elementType: "geometry", stylers: [{ "color": "#eeeeee" }] },
                        { featureType: "water", elementType: "geometry", stylers: [{ "color": "#c9c9c9" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#9e9e9e" }] }
                    ],
                    2: [
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { featureType: "poi.park", elementType: "geometry", stylers: [{ "color": "#181818" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#616161" }] },
                        { featureType: "poi.park", elementType: "labels.text.stroke", stylers: [{ "color": "#1b1b1b" }] },
                        { elementType: "geometry", stylers: [{ "color": "#212121" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#212121" }] },
                        { featureType: "administrative", elementType: "geometry", stylers: [{ "color": "#757575" }] },
                        { featureType: "administrative.country", elementType: "labels.text.fill", stylers: [{ "color": "#9e9e9e" }] },
                        { featureType: "administrative.locality", elementType: "labels.text.fill", stylers: [{ "color": "#bdbdbd" }] },
                        { featureType: "road", elementType: "geometry.fill", stylers: [{ "color": "#2c2c2c" }] },
                        { featureType: "road", elementType: "labels.text.fill", stylers: [{ "color": "#8a8a8a" }] },
                        { featureType: "road.arterial", elementType: "geometry", stylers: [{ "color": "#373737" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#3c3c3c" }] },
                        { featureType: "road.highway.controlled_access", elementType: "geometry", stylers: [{ "color": "#4e4e4e" }] },
                        { featureType: "road.local", elementType: "labels.text.fill", stylers: [{ "color": "#616161" }] },
                        { featureType: "transit", elementType: "labels.text.fill", stylers: [{ "color": "#757575" }] },
                        { featureType: "water", elementType: "geometry", stylers: [{ "color": "#000000" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#3d3d3d" }] }
                    ],
                    3: [
                        { elementType: "geometry", stylers: [{ "color": "#1d2c4d" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#8ec3b9" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#1a3646" }] },
                        { featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ "color": "#64779e" }] },
                        // {featureType:"administrative.country",elementType:"geometry.stroke",stylers:[{"color":"#4b6878"}]},
                        // {featureType:"administrative.province",elementType:"geometry.stroke",stylers:[{"color":"#4b6878"}]},
                        { featureType: "landscape.man_made", elementType: "geometry.stroke", stylers: [{ "color": "#334e87" }] },
                        { featureType: "landscape.natural", elementType: "geometry", stylers: [{ "color": "#023e58" }] },
                        { featureType: "poi", elementType: "geometry", stylers: [{ "color": "#283d6a" }] },
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#6f9ba5" }] },
                        { featureType: "poi", elementType: "labels.text.stroke", stylers: [{ "color": "#1d2c4d" }] },
                        // {featureType:"poi.business",stylers:[{"visibility":"off"}]},
                        { featureType: "poi.park", elementType: "geometry.fill", stylers: [{ "color": "#023e58" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#3C7680" }] },
                        { featureType: "road", elementType: "geometry", stylers: [{ "color": "#304a7d" }] },
                        // {featureType:"road",elementType:"labels.icon",stylers:[{"visibility":"off"}]},
                        { featureType: "road", elementType: "labels.text.fill", stylers: [{ "color": "#98a5be" }] },
                        { featureType: "road", elementType: "labels.text.stroke", stylers: [{ "color": "#1d2c4d" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#2c6675" }] },
                        { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ "color": "#255763" }] },
                        { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ "color": "#b0d5ce" }] },
                        { featureType: "road.highway", elementType: "labels.text.stroke", stylers: [{ "color": "#023e58" }] },
                        // {featureType:"transit",stylers:[{"visibility":"off"}]},
                        { featureType: "transit", elementType: "labels.text.fill", stylers: [{ "color": "#98a5be" }] },
                        { featureType: "transit", elementType: "labels.text.stroke", stylers: [{ "color": "#1d2c4d" }] },
                        { featureType: "transit.line", elementType: "geometry.fill", stylers: [{ "color": "#283d6a" }] },
                        { featureType: "transit.station", elementType: "geometry", stylers: [{ "color": "#3a4762" }] },
                        { featureType: "water", elementType: "geometry", stylers: [{ "color": "#0e1626" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#4e6d70" }] },
                    ],
                    4: [
                        // { featureType: "poi", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#d59563" }] },
                        // { featureType: "poi.business", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.park", elementType: "geometry", stylers: [{ "color": "#263c3f" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#6b9a76" }] },
                        { elementType: "geometry", stylers: [{ "color": "#242f3e" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#746855" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#242f3e" }] },
                        { featureType: "administrative.locality", elementType: "labels.text.fill", stylers: [{ "color": "#d59563" }] },
                        { featureType: "road", elementType: "geometry", stylers: [{ "color": "#38414e" }] },
                        { featureType: "road", elementType: "geometry.stroke", stylers: [{ "color": "#212a37" }] },
                        { featureType: "road", elementType: "labels.text.fill", stylers: [{ "color": "#9ca5b3" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#746855" }] },
                        { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ "color": "#1f2835" }] },
                        { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ "color": "#f3d19c" }] },
                        // { featureType: "transit", stylers: [{ "visibility": "off" }] },
                        { featureType: "transit", elementType: "geometry", stylers: [{ "color": "#2f3948" }] },
                        { featureType: "transit.station", elementType: "labels.text.fill", stylers: [{ "color": "#d59563" }] },
                        { featureType: "water", elementType: "geometry", stylers: [{ "color": "#17263c" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#515c6d" }] },
                        { featureType: "water", elementType: "labels.text.stroke", stylers: [{ "color": "#17263c" }] }
                    ],
                    5: [
                        { elementType: "geometry", stylers: [{ "color": "#ebe3cd" }] },
                        { elementType: "labels.text.fill", stylers: [{ "color": "#523735" }] },
                        { elementType: "labels.text.stroke", stylers: [{ "color": "#f5f1e6" }] },
                        { featureType: "administrative", elementType: "geometry.stroke", stylers: [{ "color": "#c9b2a6" }] },
                        { featureType: "administrative.land_parcel", elementType: "geometry.stroke", stylers: [{ "color": "#dcd2be" }] },
                        { featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ "color": "#ae9e90" }] },
                        { featureType: "poi", elementType: "geometry", stylers: [{ "color": "#dfd2ae" }] },
                        { featureType: "poi", elementType: "labels.text.fill", stylers: [{ "color": "#93817c" }] },
                        { featureType: "poi.park", elementType: "geometry.fill", stylers: [{ "color": "#a5b076" }] },
                        { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ "color": "#447530" }] },
                        { featureType: "landscape.natural", elementType: "geometry", stylers: [{ "color": "#dfd2ae" }] },
                        { featureType: "road", elementType: "geometry", stylers: [{ "color": "#f5f1e6" }] },
                        { featureType: "road.arterial", elementType: "geometry", stylers: [{ "color": "#fdfcf8" }] },
                        { featureType: "road.highway", elementType: "geometry", stylers: [{ "color": "#f8c967" }] },
                        { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ "color": "#e9bc62" }] },
                        { featureType: "road.highway.controlled_access", elementType: "geometry", stylers: [{ "color": "#e98d58" }] },
                        { featureType: "road.highway.controlled_access", elementType: "geometry.stroke", stylers: [{ "color": "#db8555" }] },
                        { featureType: "road.local", elementType: "labels.text.fill", stylers: [{ "color": "#806b63" }] },
                        { featureType: "transit.line", elementType: "geometry", stylers: [{ "color": "#dfd2ae" }] },
                        { featureType: "transit.line", elementType: "labels.text.fill", stylers: [{ "color": "#8f7d77" }] },
                        { featureType: "transit.line", elementType: "labels.text.stroke", stylers: [{ "color": "#ebe3cd" }] },
                        { featureType: "transit.station", elementType: "geometry", stylers: [{ "color": "#dfd2ae" }] },
                        { featureType: "water", elementType: "geometry.fill", stylers: [{ "color": "#b9d3c2" }] },
                        { featureType: "water", elementType: "labels.text.fill", stylers: [{ "color": "#92998d" }] }
                    ],
                }
            });
        }
        if (landmarkDensity !== undefined) {
            this.applyMapStyler(landmarkDensity, {
                mapStyles,
                allOptions: {
                    'hidden': [
                        { featureType: undefined, elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative", elementType: "geometry", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.park", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.school", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.business", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.government", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.local", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.highway", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "transit", elementType: "labels.icon", stylers: [{ "visibility": "off" }] }
                    ],
                    'low': [
                        { featureType: "poi", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.park", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.school", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.business", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.government", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.local", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "transit", elementType: "labels.icon", stylers: [{ "visibility": "off" }] }
                    ],
                    'normal': [
                        { featureType: "poi", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.park", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.school", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.business", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.government", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.local", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "transit", elementType: "labels.icon", stylers: [{ "visibility": "on" }] }
                    ],
                    'high': [
                        { featureType: "poi", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.park", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.school", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi.business", elementType: "labels.icon", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi.government", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.local", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ "visibility": "on" }] },
                        { featureType: "transit", elementType: "labels.icon", stylers: [{ "visibility": "on" }] }
                    ],
                },
            });
        }
        if (labelDensity !== undefined) {
            this.applyMapStyler(labelDensity, {
                mapStyles,
                allOptions: {
                    'hidden': [
                        { featureType: undefined, elementType: "labels", stylers: [{ "visibility": "off" }] },
                        { elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "poi", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "road", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.local", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.highway", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.arterial", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "water", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative.land_parcel", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative.neighborhood", stylers: [{ "visibility": "off" }] }
                    ],
                    'low': [
                        { elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "road", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.local", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "road.arterial", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        { featureType: "water", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative.land_parcel", stylers: [{ "visibility": "off" }] },
                        // { featureType: "administrative.neighborhood", stylers: [{ "visibility": "off" }] },
                    ],
                    'normal': [
                        { elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "poi", elementType: "labels.text", stylers: [{ "visibility": "off" }] },
                        // { featureType: "road", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.local", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.highway", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "road.arterial", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        { featureType: "water", elementType: "labels.text", stylers: [{ "visibility": "on" }] },
                        // { featureType: "administrative.land_parcel", elementType: "labels", stylers: [{ "visibility": "off" }] },
                    ],
                    'high': [],
                },
            });
        }
        let mapStyleArray = Object.keys(mapStyles).map((k1) => {
            return Object.keys(mapStyles[k1]).map((k2) => mapStyles[k1][k2]).flat();
        }).flat().sort((a, b) => {
            let diff = ((a.featureType ?? ' ').localeCompare(b.featureType ?? ' '));
            if (diff !== 0) {
                return diff;
            }
            return ((a.elementType ?? ' ').localeCompare(b.elementType ?? ' '));
        });
        // Remove subsettings for items where
        const invisibleItems = mapStyleArray.filter(item => {
            return item.stylers[0]?.visibility === 'off';
        });
        // Find indexes of items to remove
        const itemsToRemove = invisibleItems.map(item => {
            const index = mapStyleArray.findIndex((subitem) => subitem.featureType?.startsWith(item.featureType ?? '') && subitem.featureType?.startsWith(`${item.featureType}.`));
            // const index = mapStyleArray.findIndex((subitem) => item.featureType === subitem.featureType && subitem.elementType?.startsWith(`${item.elementType}.`))
            // if (index !== -1) {
            //   console.log('parent', item, ' removing', mapStyleArray[index])
            // }
            return index;
        }).filter(v => v !== -1).sort().reverse();
        // Delete subsettings
        itemsToRemove.map(index => mapStyleArray.splice(index, 1));
        if (AEngine.isDevelopmentMode) {
            AEngine.log('styles', mapStyleArray);
        }
        map.set('styles', mapStyleArray);
        return { landmarkDensity, labelDensity, mapStyle };
    }
    applyMapStyler(value, opt) {
        const { allOptions, mapStyles } = opt;
        const stylesToApply = allOptions[value] ?? [];
        let logger = {};
        for (let s of stylesToApply) {
            if (!mapStyles.hasOwnProperty(s.featureType)) {
                mapStyles[s.featureType] = {};
            }
            if (!mapStyles[s.featureType].hasOwnProperty(s.elementType)) {
                mapStyles[s.featureType][s.elementType] = {
                    featureType: s.featureType,
                    elementType: s.elementType,
                    stylers: []
                };
            }
            var record = mapStyles[s.featureType][s.elementType];
            const logKey = [s.featureType, s.elementType].filter(v => v ? true : false).join('-');
            if (!logger.hasOwnProperty(logKey)) {
                logger[logKey] = 0;
            }
            logger[logKey]++;
            record.stylers = record.stylers.concat(s.stylers);
            // mapStyles[s.featureType!][s.elementType!].stylers.push(...s.stylers)
        }
        // AEngine.log('Counts:', logger)
        return value;
    }
    genMapPopoverItem(map, { geoType, text }) {
        const $a = $(/*html*/ `
      <a geoType="${geoType}" isLoaded="${geoService.isGeoDataCached(geoType) ? '1' : '0'}">
        <div class="noselect ns-children">
          <label class="form-checkbox">
            <input type="checkbox" class="hidden noselect"> <i class="form-icon"></i>
            ${text}
          </label>
        </div>
      </a>
    `);
        const $checkbox = $a.find(`[type="checkbox"]`);
        $checkbox.on('refreshstate', (e) => {
            $a.attr('isLoaded', geoService.isGeoDataCached(geoType) ? '1' : '0');
            $checkbox.prop('checked', (map._geoObjectsVisible ?? {})[geoType]);
        });
        $a.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            $checkbox.trigger('change');
            $checkbox.trigger('refreshstate');
        });
        Events.on('GeoResponseStored', () => {
            this.refreshGeoLayerState(geoType);
        });
        return { $a, $checkbox, geoType };
    }
    refreshGeoLayerState(geoType) {
        const attrSelector = geoType !== undefined ? `geotype="${geoType}"` : 'geotype';
        const $aArr = $(`.aci-map [uid="scale-toggle"] a[${attrSelector}]`).toArray().map(a => $(a));
        $aArr.map($a => { $a.find('[type="checkbox"]').trigger('refreshstate'); });
    }
    editStyle({ map, find, defaultValue, behaviour }) {
        const styles = map.get('styles') || [];
        let index = -1;
        styles.map((style, i) => {
            if (find(style)) {
                index = i;
            }
        });
        if (index === -1) {
            index = styles.push(defaultValue()) - 1;
        }
        styles[index] = behaviour(styles[index]);
        map.set('styles', styles);
    }
    async showPreferences(map) {
        const form = new AFormInstance({
            ignoreWildcards: true,
            formInputs: [
                { id: 'mapStyle', type: 'select', options: mapStyleOptions },
                { id: 'labelDensity', type: 'select', options: densityOptions, width: 'col-6' },
                { id: 'landmarkDensity', type: 'select', options: densityOptions, width: 'col-6' }
            ]
        });
        const $form = await form.generate({ translate: true, wrapInColumns: true });
        await form.injectFormData({
            formData: {
                mapStyle: preferenceService.get(APrefs.MAP_STYLE) ?? '0',
                labelDensity: preferenceService.get(APrefs.MAP_LABEL_DENSITY) ?? 'normal',
                landmarkDensity: preferenceService.get(APrefs.MAP_LANDMARK_DENSITY) ?? 'normal',
            }
        });
        await form.initFormValidation();
        $form.find('input, :input').on('change', (e) => {
            setTimeout(() => {
                const formData = form.extractFormData({ cleanData: true });
                this.applyMapPreferences(map, {
                    mapStyle: Number(formData.mapStyle),
                    labelDensity: formData.labelDensity,
                    landmarkDensity: formData.landmarkDensity,
                });
            }, 10);
        });
        const events = Alerts.show({
            translatedTitle: await Translate.get('Map Preferences'),
            buttons: ALERT_BUTTONS.saveCancel,
            content: $form
        });
        events.on(ALERT_STATUS.ON_MODAL_CLOSED, ({ action }) => {
            if (action === ALERT_STATUS.ON_ACTION_PROCEED) {
                const mapPrefs = form.extractFormData({ cleanData: true, setInternalFormData: true });
                AEngine.log('Saving Map Preferences', mapPrefs);
                preferenceService.setAll({
                    [APrefs.MAP_STYLE]: mapPrefs.mapStyle,
                    [APrefs.MAP_LABEL_DENSITY]: mapPrefs.labelDensity,
                    [APrefs.MAP_LANDMARK_DENSITY]: mapPrefs.landmarkDensity,
                });
                // preferenceService.set(APrefs.MAP_STYLE, mapPrefs.mapStyle)
                // preferenceService.set(APrefs.MAP_LABEL_DENSITY, mapPrefs.labelDensity)
                // preferenceService.set(APrefs.MAP_LANDMARK_DENSITY, mapPrefs.landmarkDensity)
            }
            this.applyMapPreferences(map);
        });
        events.on(ALERT_STATUS.ON_ACTION_CANCEL, () => {
            AEngine.log('Cancel');
        });
    }
    toggleMapLabels(map) {
        const $mapEle = $(map.getDiv());
        let density = preferenceService.load(APrefs.MAP_LABEL_DENSITY, 'normal');
        if ($mapEle.attr('quick-hide-labels')) {
            $mapEle.removeAttr('quick-hide-labels');
            this.applyMapPreferences(map, { labelDensity: density });
        }
        else {
            $mapEle.attr('quick-hide-labels', '1');
            this.applyMapPreferences(map, { labelDensity: 'hidden' });
        }
    }
    setMapType(map, mapType) {
        map.setMapTypeId(mapType);
    }
    genDropdownLink(opt) {
        const { displayText, icon, isCheckbox = false } = opt;
        return (isCheckbox) ? $(/*html*/ `
      <a>
        <div class="noselect ns-children">
          <label class="form-checkbox">
            <input type="checkbox" class="hidden noselect">
            <i class="form-icon"></i> ${displayText ?? ''}
          </label>
        </div>
      </a>
    `) : $(/*html*/ `<a><div class="noselect ns-children">${icon ?? ''}${displayText ?? ''}</div></a>`);
    }
    async createMapDropdown({ map, mapElement, order, lockPreferences }) {
        let categories = [
            { displayText: 'Toggle Labels', click: () => this.toggleMapLabels(map), icon: '<i class="fa-solid fa-tag fa-fw mr-1"></i>' },
            { displayText: 'Reset Viewport', click: () => map.resetBounds(), icon: '<i class="fa-solid fa-users-viewfinder fa-fw mr-1"></i>' },
        ];
        // Experimental Feature
        if (stateService.isUserACI()) {
            categories.push({ displayText: `Preferences`, click: () => this.showPreferences(map), icon: `<i class="fa-solid fa-flask fa-fw mr-1"></i>` });
        }
        await Loading.waitForPromises(Translate.get(categories.map(({ displayText }) => displayText))).then(t => {
            categories.map(c => c.displayText = t[c.displayText]);
        });
        const inputs = categories.map(category => {
            const $link = this.genDropdownLink(category);
            const $c = $link.find('[type=checkbox]');
            let toggle = false;
            let toggleAction = async () => {
                category.click.call(this);
                $c.prop('checked', toggle);
                toggle = !toggle;
            };
            $link.on('click', () => { toggleAction().catch(AError.handle); });
            $c.on('change', () => { toggleAction().catch(AError.handle); });
            return $link;
        });
        this.applyMapPreferences(map);
        menuService.addMapDropdown(inputs, {
            map,
            mapElement,
            icon: 'fa-solid fa-bars',
            order,
            position: MAP_POSITION.TOP_LEFT
        });
    }
    /**
     * @deprecated
     * Updates visibility of polygons, so if the area isn't selected, the polygon won't show up
     */
    updateStaticPolygons(map) {
        const cache = map?._geoInstances ?? this.cache ?? {};
        const geoObjectsVisible = map?._geoObjectsVisible ?? PageScript.geoObjectsVisible;
        for (let geoType in cache) {
            if (geoObjectsVisible && geoObjectsVisible[geoType]) {
                cache[geoType].map(scale => scale.setOptions({ visible: true }));
            }
        }
        return Promise.resolve();
    }
    getGeoObjectColor(mapOption, geoObject) {
        // const defaultColorConf = { strokeColor: '#880000', strokeOpacity: 1.0, fillColor: '#FF0000', fillOpacity: 0.5 }
        // const geoType = this.mapOptionToGeoType(mapOption)
        // // TODO: Find out what to use for coloring
        // if (geoObject.Attributes["KLEUR"]) {
        //   const hexColor = geoObject.Attributes["KLEUR"]
        //   colorConfig.fillColor = hexColor
        //   colorConfig.strokeColor = new AColor(hexColor).hsv.lerpTo(new AColor('#000000').hsv, 0.5).hexi
        // }
        // if (geoObject.Attributes["BO.Color"]) {
        //   const bo_color = this.getLegendColor(geoObject.Attributes["BO.Color"], true)
        //   if (bo_color) {
        //     colorConfig.fillColor = bo_color.fill
        //     colorConfig.strokeColor = bo_color.stroke
        //   }
        // }
        return { strokeColor: '#880000', strokeOpacity: 1.0, fillColor: '#FF0000', fillOpacity: 0.5 };
    }
    getGeoObjectZIndex(mapOption) {
        switch (mapOption) {
            case MAP_OPTIONS.Region: return .1;
            case MAP_OPTIONS.Area: return .2;
            case MAP_OPTIONS.RouteArea: return .3;
            case MAP_OPTIONS.Segment: return .4;
            case MAP_OPTIONS.Zone: return .5;
            case MAP_OPTIONS.TemporaryZone: return .55;
            case MAP_OPTIONS.ParkingSpace: return .6;
            case MAP_OPTIONS.GeneratedParkingSpace: return .7;
            case MAP_OPTIONS.SplitParkingSpace: return .8;
            case MAP_OPTIONS.DirectedWaySegment: return .9;
            case MAP_OPTIONS.WaySegment: return 1.0;
            case MAP_OPTIONS.RouteOption: return 1.1;
            case MAP_OPTIONS.RouteIntersection: return 1.2;
            case MAP_OPTIONS.Address: return 1.3;
            case MAP_OPTIONS.ParkingMachine: return 1.4;
        }
    }
    /**
     * Toggles all polygon scales off except for the one given as argument
     * @param geoType
     */
    async toggleTo(map, geoType, click) {
        await Promise.all([
            this.toggle(map, geoType, click, true),
            ...Object.keys(map._geoObjectsVisible).map((key) => {
                const geoType = key;
                return (map._geoObjectsVisible[geoType] === true) ? this.toggle(map, geoType, click, false) : Promise.resolve();
            }),
        ]);
    }
    async toggle(map, input, clickEvent, visible) {
        try {
            // const isGeoType = ALL_GEO_TYPES.includes(input)
            const geoType = (typeof input === 'number') ? this.mapOptionToGeoType(input) : input;
            const mapOption = this.geoTypeToMapOption(geoType);
            // if (this.cache[geoType]) {
            // if (clickEvent) {
            //   for (const polygon of this.cache[geoType] ?? []) {
            //     google.maps.event.addListener(polygon, "click", clickEvent)
            //   }
            // }
            // this.redirectPolygons(map, this.cache[geoType] ?? [])
            // return this.cache[geoType]
            // }
            // TODO: Move geoObjectsVisible to map instances
            if (!map._geoObjectsVisible) {
                throw new Error(`AMap.geoObjectsVisible is not defined!`);
            }
            map._geoObjectsVisible[geoType] = (visible !== undefined) ? visible : !map._geoObjectsVisible[geoType];
            visible = map._geoObjectsVisible[geoType];
            AEngine.warn(`Setting %p${geoType}%c Visible:`, visible);
            if (this.areGeoInstancesCached(map, mapOption)) {
                await asyncMapArray(this.getGeoInstancesOnMap(map, mapOption) ?? [], 100, (geoInstance) => { geoInstance.setOptions({ map, visible: visible }); });
                return;
            }
            if (visible === true) {
                // if (!geoService.isGeoDataCached(geoType)) {
                //   // TODO: ....
                //   await geoService.load(map, mapOption, {parseToMap: true, addClickListener: clickEvent})
                //   return
                // }
                await geoService.load(map, mapOption, { parseToMap: true, addClickListener: clickEvent });
                return;
            }
            await asyncMapArray(this.getGeoInstancesOnMap(map, mapOption) ?? [], 100, (geoInstance) => { geoInstance.setOptions({ map, visible: visible }); });
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async toggleAny(opt, visibleOpt) {
        // const { collectionKey, boolKey, loadPolygons } = opt
        // if (!this.hasOwnProperty(collectionKey)) {
        //   this[collectionKey] = await loadPolygons({ parseToMap: true, ignoreOutsideBounds: true })
        // }
        // const visible = (visibleOpt !== undefined) ? visibleOpt : PageScript[boolKey]
        // const collection = this[collectionKey]
        // PageScript[boolKey] = visible
        // if (collection instanceof Array) {
        //   await geoService.setVisibility(collection, { parseToMap: visible, ignoreOutsideBounds: true })
        //   await asyncMapArray(collection, 100, (polygon) => {
        //     polygon.setOptions({ visible })
        //   })
        // } else {
        //   await asyncMapArray(Object.keys(collection), 100, (key) => {
        //     collection[key].setOptions({ visible })
        //   })
        // }
        // if (opt.enableExperimentalLoading === true) {
        //   this.initExperimentalLoading(opt)
        // }
    }
    initExperimentalLoading(opt) {
        AEngine.error(`// TODO: Implementation`);
        // if (this.scaleOpts.hasOwnProperty(collectionKey)) {
        //   if (PageScript[boolKey] === false) {
        //     this.scaleOpts[collectionKey].listener.remove()
        //     delete this.scaleOpts[collectionKey]
        //   }
        //   return
        // }
        // const listener = google.maps.event.addListener(PageScript.map, "idle", () => {
        //   this.experimentalRefresh(this.scaleOpts[collectionKey].array)
        // })
        // this.scaleOpts[collectionKey] = {
        //   array: this[collectionKey],
        //   listener: listener
        // }
    }
    async experimentalRefresh(geoInstances) {
        await geoService.setVisibility(geoInstances, { parseToMap: true, ignoreOutsideBounds: true });
    }
    prepareMapItems(bitmask, options) {
        const opt = Object.assign({
            showSearch: false,
            showOneScale: false,
            createToggleItems: true,
            updateStaticPolygons: false,
            allowExport: false,
            showLegend: false,
            fitPolygons: false,
            click: coreMapService.onGeoClick,
            enableExperimentalLoading: false,
        }, options || {});
        return Loading.waitForPromises(this._prepareMapItems(bitmask, opt));
    }
    async _prepareMapItems(bitmask, options) {
        const { createToggleItems, updateStaticPolygons, showLegend, allowExport, showOneScale, click, fitPolygons, enableExperimentalLoading, createLabel, showSearch, skipFit } = options;
        const map = options.map || PageScript.map;
        const mapElement = map.getDiv();
        const output = await Loading.waitForPromises([
            Promise.resolve().then(() => this.mapOverlayService.addGradientOverlay(mapElement)),
            // Map Hamburger menu
            this.createMapDropdown({ order: 1, map, mapElement }),
            // Map Scale toggle menu
            (createToggleItems === true) ? this.createMapToggleSettings(bitmask, { order: 2, showOneScale, click, fitPolygons, enableExperimentalLoading, map }) : Promise.resolve(),
            Promise.resolve().then(() => console.error(`// TODO: Implement ShowSearch`)),
            (showSearch) ? menuService.addMapButton({
                map,
                mapElement,
                order: 4,
                icon: 'fa-solid fa-magnifying-glass',
                position: MAP_POSITION.TOP_LEFT,
            }).then($btn => {
                const $fa = $btn.find('i');
                $btn.on('click', () => {
                    const v = toggleMapSearch({
                        ele: '#map',
                        map: map
                    });
                    $fa.toggleClass('fa-magnifying-glass', !v);
                    $fa.toggleClass('fa-xmark', v);
                });
            }) : Promise.resolve(),
            menuService.addMapButtonRadio({
                map,
                mapElement,
                order: 98,
                titles: ['Map', 'Satellite'],
                icons: ['fa-regular fa-leaf-maple', 'fa-solid fa-satellite-dish'],
                position: MAP_POSITION.TOP_RIGHT
            }).then(([$map, $satellite]) => {
                $map.on('click', _ => this.setMapType(map, google.maps.MapTypeId.ROADMAP));
                $satellite.on('click', _ => this.setMapType(map, google.maps.MapTypeId.HYBRID));
            }),
            (navigator?.geolocation) ? menuService.addMapButton({
                map,
                mapElement,
                order: 99,
                icon: 'fa-solid fa-location-arrow',
                position: MAP_POSITION.TOP_RIGHT,
            }).then($btn => {
                $btn.on('click', () => {
                    const latLng = new google.maps.LatLng(Gps.Latitude, Gps.Longitude);
                    if (!AIsLatLngValid(latLng)) {
                        navigator.geolocation.getCurrentPosition(function (pos) {
                            var me = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
                            map.setCenter(me);
                            map.setZoom(20);
                        }, (error) => {
                            AError.handle(new Error(error.message));
                        });
                    }
                    else {
                        map.setCenter(latLng);
                        map.setZoom(20);
                    }
                });
            }) : Promise.resolve(),
            // Map Fullscreen button
            menuService.addMapButton({
                map,
                mapElement,
                order: 100,
                icon: 'fa-solid fa-expand',
                position: MAP_POSITION.TOP_RIGHT
            }).then($btn => {
                $btn.on('click', _ => toggleMapFullScreen({ mapElement }));
                const $fa = $btn.find('i');
                Events.on(EVENTS.TOGGLE_FULLSCREEN, (v) => {
                    $fa.toggleClass('fa-compress', v);
                    $fa.toggleClass('fa-expand', !v);
                });
            }),
            // Update polygon visibility
            (updateStaticPolygons === true) ? this.updateStaticPolygons(map) : Promise.resolve(),
            // Show legend if enabled
            (showLegend === true) ? this.setDetectionsLegendDefault({ mapElement }) : Promise.resolve(),
            // Show export if enabled
            (allowExport) ? this.createDownloadButton({ map, order: 10, mapElement }) : Promise.resolve(),
            // Create detection count at the bottom of the map
            (createLabel === true) ? this.createCountLabel() : Promise.resolve(),
            // Change map bounds to fit the contents
            (skipFit !== true) ? Promise.resolve().then(_ => map?.fit()) : Promise.resolve(),
            // Create safe-zone for streetview btn to appear
            (map?.streetViewControlOptions?.position === 7) ? Promise.resolve()
                .then(_ => AEngine.get(AMapOverlayService).setOffset(mapElement, MAP_POSITION.TOP_RIGHT, map?.streetViewControl ? '0 45px 0 0' : '0')) : Promise.resolve()
        ]);
        // Initialize resizing events
        const $map = $(mapElement);
        if (!$map.is('.map-sm,.map-xs')) {
            const onSizeChange = () => {
                const $dragParent = $map.closest('.aci-draggable-view').css('min-width', '245px');
                const collisionData = this.mapOverlayService.isCollisionBetween(mapElement, MAP_POSITION.TOP_LEFT, MAP_POSITION.TOP_RIGHT, { checkX: true });
                if (collisionData.isCollision && !$map.is('.map-xs')) {
                    const $overlay = this.mapOverlayService.getOrCreateOverlay(mapElement, MAP_POSITION.TOP_LEFT);
                    $overlay.css('width', $overlay.width());
                    $map.addClass('map-xs');
                }
                if (!collisionData.isCollision && $map.is('.map-xs')) {
                    const $overlay = this.mapOverlayService.getOrCreateOverlay(mapElement, MAP_POSITION.TOP_LEFT);
                    $overlay.css('width', '');
                    $map.removeClass('map-xs');
                }
                const isSmall = $map.width() < 600;
                $map.toggleClass('map-sm', isSmall && !collisionData.isCollision);
            };
            google.maps.event.addListener(map, 'resize', onSizeChange);
            Events.on(EVENTS.CONTENT_DRAG, onSizeChange);
        }
        this.refreshGeoLayerState();
        return output;
    }
    /**
     * Changes the color of the marker
     * @param {any} marker
     * @param {Object} options
     */
    changeColorMarker(marker, options) {
        marker.setOptions(options);
    }
    /**
     * @deprecated
     * Reverts polygon colors & opacity to the initial colors
     * @param bitmask
     */
    revertColors(bitmask = MAP_OPTIONS.All) {
        ALL_MAP_OPTIONS.filter(opt => (opt & bitmask)).map((opt) => {
            const geoType = this.mapOptionToGeoType(opt);
            if (this.cache[geoType]?.length) {
                this.cache[geoType].map((marker) => {
                    const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker.data;
                    marker.setOptions({
                        strokeColor, strokeOpacity, fillColor, fillOpacity
                    });
                });
            }
        });
    }
    /**
     * Finds the markers that are saved to the PageScript object
     */
    fetchMarkers() {
        return PageScript.Markers || PageScript.markers || [];
    }
    /**
     * Deletes polygons (zones, areas, parking spaces or markers) from the map
     * @param {any} arrayOrObject
     */
    destroy(arrayOrObject) {
        if (arrayOrObject === undefined)
            return;
        const eventsToClear = ['click', 'rightclick'];
        if (arrayOrObject instanceof Array) {
            let array = arrayOrObject;
            if (array.length === 0) {
                return array;
            }
            for (let index in array) {
                for (const key of eventsToClear) {
                    google.maps.event.clearListeners(array[index], key);
                }
                array[index].setMap(null);
                delete array[index];
            }
            if (array.length) {
                array.length = 0;
                // @ts-ignore
                array = null;
            }
        }
        else {
            this.destroy(convertObjectToArray(arrayOrObject));
        }
    }
    /**
     * Unloads polygons (zones, areas, parking spaces or markers) from the map
     * @param {any} arrayOrObject polygons on the map
     * @param {Number} options type of unload
     */
    unload(arrayOrObject, options = UNLOAD_OPTIONS.AllListeners) {
        const eventsToClear = [
            'bounds_changed',
            'center_changed',
            'click',
            'dblclick',
            'drag',
            'dragend',
            'dragstart',
            'heading_changed',
            'idle',
            'maptypeid_changed',
            'mousemove',
            'mouseout',
            'mouseover',
            'projection_changed',
            'resize',
            'rightclick',
            'tilesloaded',
            'tilt_changed',
            'zoom_changed',
        ];
        if (arrayOrObject instanceof Array) {
            const array = arrayOrObject;
            if (array.length === 0) {
                return;
            }
            switch (options) {
                case UNLOAD_OPTIONS.Default:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        for (const key of eventsToClear) {
                            google.maps.event.clearListeners(array[index], key);
                        }
                        array[index].setMap(null);
                        // }
                    }
                    break;
                case UNLOAD_OPTIONS.AllListeners:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        for (const key of eventsToClear) {
                            google.maps.event.clearListeners(array[index], key);
                        }
                        array[index].setMap(null);
                        // }
                    }
                    break;
                case UNLOAD_OPTIONS.None:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        array[index].setMap(null);
                        // }
                    }
                    break;
                default:
                    throw new Error(`MapHelperService.unload(... , ${options}) contains unknown options.`);
            }
        }
        else {
            this.unload(convertObjectToArray(arrayOrObject));
        }
    }
    getColors(detectionData) {
        let FillColor = '';
        let StrokeColor = '';
        const set = (f, o) => {
            if (f !== null && FillColor === '')
                FillColor = f;
            if (o !== null && StrokeColor === '')
                StrokeColor = o;
        };
        let { IsIllegallyParked, HasParkingRight } = detectionData;
        if (HasParkingRight === null) {
            const { fill, stroke } = this.get_legend_grey(false); // AConfig.get('drawing & colors.detections.unknown')
            set(fill, stroke);
        }
        if (HasParkingRight === 0) {
            const { fill, stroke } = this.get_legend_red(false); // AConfig.get('drawing & colors.detections.noParkingRight')
            set(fill, stroke);
        }
        if (IsIllegallyParked) {
            const { stroke } = this.get_legend_brown_outline(); // AConfig.get('drawing & colors.detections.illegallyParked')
            set(null, stroke);
        }
        if (FillColor === null) {
            const { fill, stroke } = this.get_legend_green(false); // AConfig.get('drawing & colors.detections.default')
            set(fill, stroke);
        }
        return { FillColor, StrokeColor };
    }
    /**
     * Gets the bounds of the map
     */
    getMapBounds() {
        const output = {};
        const { map } = PageScript;
        if (!map) {
            return null;
        }
        const bounds = map.getBounds();
        const json = bounds.toJSON !== undefined ? bounds.toJSON() : bounds.JSON();
        Object.keys(json).map((key) => {
            if (key.length) {
                const newKey = key[0].toUpperCase() + key.substr(1);
                Object.assign(output, { [newKey]: json[key] });
            }
        });
        return output;
    }
    /**
     * Gets points of a Polygon or Marker
     * @param {any} marker Polygon or Marker
     */
    getPoints(marker) {
        if (marker == null) {
            throw new Error(`AMapHelperService.getPoints unexpected marker is not defined!`);
        }
        const path = marker.getPath();
        const length = path.getLength();
        const lnglat = [];
        for (let i = 0; i < length; i++) {
            const { lat, lng } = path.getAt(i).toJSON();
            lnglat.push([lng, lat]);
        }
        return lnglat;
    }
    onGeoClick(event) {
        AEngine.log('ACoreMapService.onGeoClick(event?: %cMouseEvent%n) %g event=', event);
        const geoInstance = this;
        const pos = AGeoUtils.calcCenter(geoInstance);
        purgatoryService.buildAndShowInfoWindowLegacy({
            data: geoInstance.data,
            marker: geoInstance,
            tableFormatter: polygons_tableformatter(),
            greyOutFields: true,
            sorting: [],
        });
        Events.tryInvoke(EVENTS.GEO_OBJECT_CLICKED, { geoInstance, pos });
    }
    addClickListeners(geoInstances, clickEvent) {
        const clickEventHandler = clickEvent ?? this.onGeoClick;
        geoInstances.map((geoInstance) => {
            google.maps.event.addListener(geoInstance, "click", clickEventHandler);
        });
    }
    addClickListener(geoInstance, clickEvent) {
        const clickEventHandler = clickEvent ?? this.onGeoClick;
        google.maps.event.addListener(geoInstance, "click", clickEventHandler);
    }
    /**
     * Display Popup window in order to filter Geo Layers on map
     * @param map map that countains the geo layers
     */
    async filterGeoLayerSelect(map) {
        try {
            // Get visible geo layers on page
            const availableLayers = ALL_GEO_TYPES.filter((geoType) => {
                return (map._geoObjectsVisible[geoType] === true);
            }).filter(v => v !== undefined);
            if (availableLayers.length === 0) {
                Alerts.show({
                    title: ALERT_TITLES.Error,
                    content: await Translate.get('You need to enable atleast 1 GeoLayer!'),
                    type: ALERTS.Error
                });
                return;
            }
            // Declare & initialize a form so the user can choose which layer he/she wants to filter
            const form = new AFormInstance({
                ignoreWildcards: true,
                formInputs: [{
                        id: 'geoLayer',
                        label: '',
                        type: 'select',
                        options: availableLayers.map((v) => ({ id: v, text: v })),
                        disallowNone: true,
                        hint: 'Please Select a Visible GeoLayer',
                    }],
            });
            const events = Alerts.show({
                translatedTitle: await Translate.get('Filter GeoLayer'),
                content: await form.generate({ translate: true }),
                buttons: ALERT_BUTTONS.filterCancel
            });
            await form.injectFormData();
            await form.initFormValidation();
            events.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
                // Upon user input, extract data from form
                const data = form.extractFormData({ cleanData: true });
                if (data === null) {
                    return false;
                }
                // Generate and display filters for the current geolayer
                Loading.waitForPromises(this.filterGeoLayers(map, data.geoLayer)).catch(AError.handle);
            });
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async filterGeoLayers(map, geoType) {
        // Convert geoType to mapOption
        const mapOption = this.geoTypeToMapOption(geoType);
        // Get all polygons of selected geo layer
        const polygons = this.getGeoInstancesOnMap(map, geoType);
        // Retreive the geoMapper in order to get data of the polygons
        const geoMapper = this.geoObjectMapper(mapOption);
        // Display a toast when no polygons are found
        if (polygons.length === 0) {
            AEngine.warn(`No Polygons loaded!`);
            return toast({ msg: await Translate.get('Please Load the GeoLayer beforehand!') });
        }
        // Compile all possible values for every attribute
        let optMapping = {};
        polygons.map(p => {
            const attrs = geoMapper[p.data.GeoId]?.Attributes ?? {};
            Object.keys(attrs).map(key => {
                const value = attrs[key];
                if (typeof value === 'number')
                    return;
                if (!optMapping.hasOwnProperty(key)) {
                    optMapping[key] = new Set();
                }
                optMapping[key].add(value);
            });
        });
        // Create id mapper in order to transform input ids to attribute keys
        // This method is used because some Attribute keys contain illegal html characters & symbols
        const idAllocator = new AIdAllocator({ padStart: 0, startId: 0 });
        const form = new AFormInstance({
            ignoreWildcards: true,
            formInputs: Object.keys(optMapping).map(field => {
                const id = idAllocator.getNextId({ prefix: 'inp-' });
                const baseOpt = { id, label: field, width: 'col-4' };
                if (optMapping[field].size > 100) {
                    return { ...baseOpt, type: 'text', minlength: 0, maxlength: 256 };
                }
                const arr = [...optMapping[field]];
                if (arr.length === 1 && arr[0] == '') {
                    return { ...baseOpt, type: 'text', minlength: 0, maxlength: 256 };
                }
                return { ...baseOpt, type: 'select', options: [{ id: '%', text: 'Any' }, ...arr.map(v => ({ id: v, text: v }))] };
            })
        });
        // Display Second Alert containing all the geo layer filters
        const events = Alerts.show({
            translatedTitle: await Translate.get('Filter GeoLayers'),
            content: await form.generate({ translate: false, wrapInColumns: true }),
            type: ALERTS.Large
        });
        await form.injectFormData();
        await form.initFormValidation();
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            // Prepare for counting visible/invisible geo layers
            let visible = 0, total = polygons.length;
            // Extract data from filter modal
            const tmpData = form.extractFormData({ cleanData: true });
            // Declare data object to dump filters in
            const data = {};
            // Cache input type, so that we can use wildcards when using with text inputs
            const inputTypes = {};
            // Transform ids back to orginal keys & store it in data obj
            Object.keys(tmpData).filter(str => tmpData[str] !== '').map(str => {
                const k = Object.keys(optMapping)[str.split('inp-').pop()];
                data[k] = tmpData[str];
                inputTypes[str] = form.formInputs.find(inp => inp.id === str)?.type;
            });
            if (AEngine.isDevelopmentMode) {
                AEngine.log('filterGeoLayers', { optMapping, form, data });
            }
            // Get filter key to start searching
            const filterKey = (Object.keys(data) ?? []).shift(); // TODO: Allow multiple filterKeys
            // Get filter value to start looking for
            const find = data[filterKey]?.toLowerCase();
            if (find !== undefined) {
                // Whether the search should be wildcard
                const isText = Object.values(inputTypes)[0];
                // Process all polygons
                polygons.map(geoInstance => {
                    const currData = geoMapper[geoInstance.data.GeoId]?.Attributes[filterKey]?.toLowerCase();
                    const isMatch = isText ? (currData?.indexOf(find) !== -1) : (currData === find);
                    if (isMatch) {
                        visible++;
                    }
                    // Toggle polygon visibility
                    geoInstance.setVisible(isMatch);
                });
            }
            // Center on geo layers if the results are few and the geo layer is small
            if (visible < 30 && mapOption >= MAP_OPTIONS.Zone) {
                map.focusOnGeoLayers();
            }
            // Display toast
            toast({ msg: await Translate.get(`Showing ${visible}/${total} GeoLayers of type: ${geoType}`), timeout: 7500 });
        });
    }
    async initMapRightClick(opt) {
        const { $parent } = Object.assign({ $parent: AEngine.get(APopoverService).$container }, opt);
        let opts = [
            { label: 'Focus On Detections', click: (map) => map.focusOnMarkers() },
            { label: 'Focus On Geo Layers', click: (map) => map.focusOnGeoLayers() },
            { label: 'Filter Geo Layers', click: (map) => Loading.waitForPromises(this.filterGeoLayerSelect(map)) },
        ];
        const labels = opts.map(opt => opt.label);
        await Loading.waitForPromises(Translate.get(labels).then(t => {
            opts.map(c => c.displayText = t[c.label]);
        }));
        const allowMenu = (t) => {
            return ($(t).closest('.aci-map').length > 0 || $(t).is('.aci-map'))
                && ($(t).closest('.map-overlay').length === 0)
                && ($(t).closest('.legends').length === 0);
        };
        const extractVars = (t) => {
            const $map = $(t).is('.aci-map') ? $(t) : $(t).closest('.aci-map');
            return { map: $map.data('map'), mapElement: $map.get(0), $map };
        };
        const moveToMouse = (opt) => {
            $(`${opt.popoverSelector}`).css(opt);
        };
        $(document).on('contextmenu', '.aci-map', (e) => {
            if (!allowMenu(e.target)) {
                return;
            }
            const { $map, map } = extractVars(e.target);
            const uid = idAllocatorService.getNextId({ prefix: 'ctxm' });
            const qs = `.sidebar-popover[uid="${uid}"]`;
            // Build context menu
            const $ctxm = $(`<div id="popover-generic" class="sidebar-popover" uid="${uid}"><ul></ul></div>`);
            if ($map.closest('.modal').length > 0) {
                $ctxm.css('z-index', '1050');
            }
            opts.map((cOpt) => $ctxm.find('ul').append($('<li></li>').append(this.genDropdownLink(cOpt).on('click', () => {
                cOpt.click.apply(this, [map]);
                menuService.setVisible($(`${qs}`), false);
                $ctxm.remove();
            }))));
            map.get('contextmenu')?.remove();
            map.set('contextmenu', $ctxm);
            sleep(160).then(() => {
                $(document).on('mouseleave', `${qs},#${uid}`, (e) => menuService.setVisible($(`${qs}`), false));
            });
            Events.on(EVENTS.DESTRUCT, () => { $(document).off('mouseleave', `${qs},#${uid}`); $ctxm.remove(); });
            $parent.append($ctxm);
            menuService.setVisible($ctxm, true);
            moveToMouse({ popoverSelector: qs, left: e.clientX - 5, top: e.clientY - 5 });
        });
    }
    getMapIcon(nodeType, defaultValue = null) {
        switch (nodeType) {
            case "ExternalDevice": return '/img/huisstijl/pda_los_72ppi_rgb.png';
            case "Pda": return '/img/huisstijl/pda_los_72ppi_rgb.png';
            case "ScanAuto": return '/img/huisstijl/scanacar_los_72ppi_rgb.png';
            case "ScanScooter": return '/img/huisstijl/scooter_los_72ppi_rgb.png';
            case "ScanSegway": return '/img/huisstijl/segway_los_72ppi_rgb.png';
            case "ScanBike": return '/img/huisstijl/fiets_los_72ppi_rgb.png';
            case "BackOffice": return '/img/huisstijl/centrale_los_72ppi_rgb.png';
            case "CentralVerification": return '/img/huisstijl/centrale_los_72ppi_rgb.png';
            case "ScanCam": return '/img/huisstijl/paal_72ppi_rgb.png';
            default: return defaultValue;
        }
    }
    lerp(from, to, t) {
        const sphericalGeometry = google.maps.geometry.spherical;
        const heading = sphericalGeometry.computeHeading(from, to);
        const distanceToTarget = sphericalGeometry.computeDistanceBetween(from, to);
        const pos = sphericalGeometry.computeOffset(from, distanceToTarget * t, heading);
        const mag = sphericalGeometry.computeDistanceBetween(from, pos);
        return { pos, mag };
    }
    clamp01(v) {
        if (v > 1.0) {
            return 1.0;
        }
        else if (v < 0.0) {
            return 0.0;
        }
        return v;
    }
}

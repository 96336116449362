import { AConfig } from "../classes/AConfig.js";
import { AError } from "../classes/AError.js";
import { AImageHelper } from "../classes/AImageHelper.js";
import { APhotoFetcher } from "../classes/APhotoFetcher.js";
import { AResponse } from "../classes/AResponse.js";
import { createMap } from "../core/maps/ACoreMapService.js";
import { getCenterAny } from "../core/maps/mapToolsTmp.js";
import { AIsLatLngValid, ShowMapScans } from "../utils/maps.js";
import { AGetSelectNamesFromQuery, DetectionsFullSingle } from "../utils/query.js";
import { AFormatDate } from "../utils/tools.js";
import { ALERTS, ALERT_TITLES } from "./AAlertService.js";
export class ADetectionService {
    pack(res, index, excluded) {
        return purgatoryService.responseToTable(res, {
            // className: 'table table-striped table-hover',
            excluded,
            index
        });
    }
    async showOnMap({ DetectionId, DetectionDeviceId, DetectionTime }) {
        const height = Math.round(window.innerHeight / 3 * 2);
        const events = Alerts.show({
            title: ALERT_TITLES.None,
            content: ( /*html*/`
      <div class="columns col-gapless">
        <div class="column col-4 sideview-split" id="modal-info-window-container" style="height: ${height}px; overflow-y: auto;"></div>
        <div class="column col-8">
          <div id="modal-map" class="full-width" style="height: ${height}px"></div>
        </div>
      </div>
      `),
            type: ALERTS.Large
        });
        const $map = events.$ele.find('#modal-map');
        const $splitView = events.$ele.find('#modal-info-window-container');
        const newHeight = events.$ele.find('.modal-body').height() || '300';
        $map.height(newHeight + 'px');
        $splitView.height(newHeight + 'px');
        const map = createMap($map, {
            position: AConfig.get('general.map.pos', { lat: 0, lng: 0 }),
            zoom: 21
        });
        // Potential MySQL Injection?
        const selectColumns = AGetSelectNamesFromQuery(DetectionsFullSingle).join(', ');
        return Loading.waitForPromises(requestService.query({
            Name: "MapSearch",
            Query: (`
          SELECT ${selectColumns}
          FROM (${DetectionsFullSingle}) full
          ORDER BY DetectionId
          LIMIT :Limit
        `),
            Params: {
                DetectionId,
                DetectionDeviceId,
                Limit: 10
            },
            Language: Language,
            Translate: ["VehicleType", "ParkingAreaType", "ParkingRightType", "IsIllegallyParked"]
        }, { cacheQuery: true })).then((response) => {
            if (response.Rows.length === 0) {
                return Alerts.noResults(); //.on(ALERT_STATUS.ON_MODAL_CLOSED, () => {})
            }
            const markers = ShowMapScans({
                response,
                map: map,
                infoParent: '#modal-info-window-container'
            });
            this.selectMarkerAndDisplayPda(map, response, markers);
        }).catch(AError.handle);
    }
    async selectMarkerAndDisplayPda(map, response, markers) {
        const hasMarkers = response.Rows.length > 0;
        const shouldDisplayPda = response.Rows.length === 1;
        if (hasMarkers) {
            const data = new AResponse(await purgatoryService.onMarkerClickRealtime(markers[0]));
            if (shouldDisplayPda) {
                const detection = data.First;
                console.log({ shouldDisplayPda, detection });
                const { Verification, VerificationLatitude, VerificationLongitude } = detection;
                if (AIsLatLngValid([VerificationLatitude, VerificationLongitude])) {
                    this.drawLine(map, getCenterAny(markers[0]), { lat: VerificationLatitude, lng: VerificationLongitude });
                }
            }
        }
    }
    drawLine(map, from, to) {
        const lineSymbol = {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            scale: 4,
        };
        // Create the polyline, passing the symbol in the 'icons' property.
        // Give the line an opacity of 0.
        // Repeat the symbol at intervals of 20 pixels to create the dashed effect.
        const dottedLineToPda = new google.maps.Polyline({
            path: [from, to],
            strokeOpacity: 0,
            icons: [{
                    icon: lineSymbol,
                    offset: "0",
                    repeat: "20px",
                }],
            map: map,
        });
        return dottedLineToPda;
    }
    async findHistory(Params) {
        const WHERE = [
            'DetectionId=:DetectionId',
            'DetectionDeviceId=:DetectionDeviceId'
        ].join(' AND ');
        const [detections, detections_geo, parkingrights, verifications, corrections] = await Loading.waitForPromises(requestService.queries([
            // Potential MySQL Injection?
            { Query: `SELECT DetectionTime AS _DATE, a.* FROM detections a WHERE ${WHERE}`, Params, Language },
            // Potential MySQL Injection?
            { Query: `SELECT * FROM detections_geo WHERE ${WHERE}`, Params, Language },
            // Potential MySQL Injection?
            { Query: `SELECT ParkingRightCheckTime AS _DATE, a.* FROM parkingrights a WHERE ${WHERE}`, Params, Language },
            // Potential MySQL Injection?
            { Query: `SELECT VerificationEndTime as _DATE, a.* FROM verifications a WHERE ${WHERE}`, Params, Language },
            // Potential MySQL Injection?
            { Query: `SELECT CorrectionTime AS _DATE, a.* FROM corrections a WHERE ${WHERE}`, Params, Language }
        ]));
        const photos = await Loading.waitForPromises(new APhotoFetcher().fetchOverviewPhoto(Params.DetectionId, Params.DetectionDeviceId));
        const translations = await Loading.waitForPromises(Translate.get([
            'Overview Images', 'Detections', 'Geography', 'ParkingRights', 'Verifications', 'Corrections'
        ]));
        const htmls = [];
        // ========================================= DETECTIONS ========================================= //
        if (detections.Rows.length) {
            const iDate = detections.Columns.indexOf('_DATE');
            const d = new Date(detections.Rows[0][iDate]);
            if (photos.length > 0) {
                htmls.push([d, (`
                <div class="columns">
                  <div class="column col-12">
                    <a href="#" class="h6 load-images-async">
                      <i class="icon icon-arrow-right mr-1"></i>
                      ${AFormatDate(d)} &#124; ${translations['Overview Images']}
                    </a>
                  </div>
                  <div class="column col-12 hidable" style="display: none">
                    <div class="overview-image-container" style="height: 200px; overflow: auto;"></div>
                  </div>
                </div>
              `)]);
            }
            htmls.push([d, `
              <div class="columns">
                <div class="column col-12">
                  <a href="#" class="h6">
                    <i class="icon icon-arrow-right mr-1"></i>
                    ${AFormatDate(d)} &#124; ${translations['Detections']}
                  </a>
                </div>
                <div class="column col-12 hidable" style="display: none">
                  ${this.pack(detections, 0, ['_DATE'])}
                </div>
              </div>
            `]);
        }
        if (detections_geo.Rows.length) {
            const d = htmls[0][0];
            htmls.push([d, `
              <div class="columns">
                <div class="column col-12">
                  <a href="#" class="h6">
                    <i class="icon icon-arrow-right mr-1"></i>
                    ${AFormatDate(d)} &#124; ${translations['Geography']}
                  </a>
                </div>
                <div class="column col-12 hidable" style="display: none">
                  ${this.pack(detections_geo, 0, ['_DATE', 'DetectionId', 'DetectionDeviceId'])}
                </div>
              </div>
            `]);
        }
        parkingrights.Rows.forEach((row, index) => {
            const iDate = parkingrights.Columns.indexOf('_DATE');
            const d = new Date(row[iDate]);
            htmls.push([d, `
              <div class="columns">
                <div class="column col-12">
                  <a href="#" class="h6">
                    <i class="icon icon-arrow-right mr-1"></i>
                    ${AFormatDate(d)} &#124; ${translations['ParkingRights']}
                  </a>
                </div>
                <div class="column col-12 hidable" style="display: none">
                  ${this.pack(parkingrights, index, ['_DATE', 'DetectionId', 'DetectionDeviceId', 'DetectionTime'])}
                </div>
              </div>
            `]);
        });
        verifications.Rows.forEach((row, index) => {
            const iDate = verifications.Columns.indexOf('_DATE');
            const d = new Date(row[iDate]);
            htmls.push([d, `
              <div class="columns">
                <div class="column col-12">
                  <a href="#" class="h6">
                    <i class="icon icon-arrow-right mr-1"></i>
                    ${AFormatDate(d)} &#124; ${translations['Verifications']}
                  </a>
                </div>
                <div class="column col-12 hidable" style="display: none">
                  ${this.pack(verifications, index, ['_DATE', 'DetectionId', 'DetectionDeviceId'])}
                </div>
              </div>
            `]);
        });
        corrections.Rows.forEach((row, index) => {
            const iDate = corrections.Columns.indexOf('_DATE');
            const d = new Date(row[iDate]);
            htmls.push([d, `
              <div class="columns">
                <div class="column col-12">
                  <a href="#" class="h6">
                    <i class="icon icon-arrow-right mr-1"></i>
                    ${AFormatDate(d)} &#124; ${translations['Corrections']}
                  </a>
                </div>
                <div class="column col-12 hidable" style="display: none">
                  ${this.pack(corrections, index, ['_DATE', 'DetectionId', 'DetectionDeviceId'])}
                </div>
              </div>
            `]);
        });
        const html = htmls.sort((a, b) => {
            // @ts-ignore
            return a[0] - b[0];
        }).map((arr) => arr[arr.length - 1]).join('<br>');
        const events = Alerts.show({
            title: ALERT_TITLES.History,
            content: html,
            type: ALERTS.Large
        });
        const { $ele } = events;
        $ele.find('.h6').on('click', (e) => {
            e.preventDefault();
            const $table = $(e.target).closest('.columns').find('.hidable');
            $table.toggle();
        });
        $ele.find('.load-images-async').one('click', async (e) => {
            e.preventDefault();
            const imageHelper = new AImageHelper({
                // $rh: $ele.find('.overview-image-container'),
                $photos: $ele.find('.overview-image-container')
            }, true);
            const imageOptions = {
                allowFilter: false,
                allowFullscreen: false,
                allowZoom: false,
                defaultSize: {
                    height: imageHelper.$photos.height()
                }
            };
            imageHelper.addImageSet(photos.map(p => p.src), imageOptions);
            imageHelper.$photos.css('height', 'auto');
        });
        return events;
    }
    setMarkerIdentifier(marker, ddid, did) {
        const DETECTIONDEVICEID_LENGTH = 15;
        const DETECTIONDEID_LENGTH = 17;
        if (!ddid || !ddid.length || ddid.length !== DETECTIONDEVICEID_LENGTH) {
            return AError.handle(`DetectionDeviceId is not correct: ${ddid} }`);
        }
        if (!did || !did.length || did.length !== DETECTIONDEID_LENGTH) {
            return AError.handle(`DetectionId is not correct: ${did}`);
        }
        Object.assign(marker, {
            Identifier: `${ddid}_${did}`,
            IdentifierSeperated: {
                DetectionDeviceId: ddid,
                DetectionId: did
            }
        });
    }
    /**
     * @param {*} marker
     * @returns {{ DetectionId: any, DetectionDeviceId: any }}
     */
    getMarkerIdentifier(marker) {
        const { IdentifierSeperated, Identifier } = marker;
        if (IdentifierSeperated && IdentifierSeperated.DetectionDeviceId && IdentifierSeperated.DetectionId) {
            return IdentifierSeperated;
        }
        if (Identifier) {
            const [DetectionDeviceId, DetectionId] = Identifier.split('_');
            return { DetectionId, DetectionDeviceId };
        }
        throw new Error(`No Marker Identifier Found!`);
    }
    setMarkerFinal(marker, data) {
        const { HasParkingRight, IsIllegallyParked, keyParkingRight, keyVerification, keyDetectionState, keyDigital, keyIllegallyParked } = data;
        Object.assign(marker, {
            _final: {
                HasParkingRight,
                IsIllegallyParked,
                ParkingRight: keyParkingRight,
                Verification: keyVerification,
                DetectionState: keyDetectionState,
                Digital: keyDigital,
                IllegallyParked: keyIllegallyParked
            }
        });
    }
}

import { Routes } from "./centralverification-routes.js";
import { AConfig } from "./classes/AConfig.js";
import { AError } from "./classes/AError.js";
import { ACCCClientSettings } from "./core/ACCCClientSettings.js";
import { AEngine, sleep } from "./core/AEngine.js";
import { AGpsDevice } from "./core/AGpsDevice.js";
import { UNLOAD_OPTIONS } from "./core/maps/AMapStructs.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "./services/AAlertService.js";
import { AEventService, EVENTS } from "./services/AEventService.js";
import { AJsonService, JSON_FILES } from "./services/AJsonService.js";
import { APolicyService } from "./services/APolicyService.js";
import { ROUTES } from "./services/ARouteService.js";
import { checkIfFullScreen, GetCookie, mergeDeep, SetCookie } from "./utils/tools.js";
const eventService = AEngine.getOrCreateInstance(AEventService);
class ImportError extends Error {
}
Object.assign(globalThis, {
    ACI_ADMIN: 'aci_admin',
    AllowLanguageChange: GetCookie('Language', () => {
        $('[action="TOGGLE_LANGUAGE"]').css('pointer-events', 'none');
        AEngine.log(`Cookies Disabled In Server Configuration.`);
        return null;
    }) != null,
    Language: GetCookie('Language', () => {
        return (navigator?.language?.split('-').shift() || 'en');
    }),
    InfoWindow: globalThis.google?.maps?.InfoWindow,
    InfoWindowInstance: globalThis.google?.maps?.InfoWindow !== undefined ? new globalThis.google.maps.InfoWindow() : undefined,
    Sessions: [],
    ServerStats: null,
    PageScript: null,
    script: null,
    Settings: null,
    Config: null,
    Translations: null,
    Menu: [],
    MenuAjax: [],
    FilterSettings: {},
    AllDevices: [],
    ScanDevices: [],
    ScanDeviceIds: {},
    DetectionUsers: [],
    VerificationUsers: [],
    VerifyDevices: [],
    GpsDevices: [],
    AreasSRID: {},
    VerificationResults: {},
    ParkingRights: [],
    FineItems: [],
    Gps: null,
    LoginCredentials: null,
    QRImageData: null,
    VehicleTypes: {},
    ParkingRightTypes: {},
    LocationTypes: {},
    ImportError: ImportError,
    baseTitle: document.title,
    STRING: 'String',
    ARRAY: 'Array',
    DeskControl: null,
    AError: AError,
    c: 0,
    msgDict: {}
});
export function DeviceIdFromDeviceName(DeviceName) {
    let Pos = DeviceName.search(/[0-9]/);
    let DeviceType = DeviceName.substring(0, Pos);
    let IndexNumber = parseInt(DeviceName.substring(Pos));
    let CustomerNumber = parseInt(Settings.NodeCustomerNumber);
    let ProjectNumber = parseInt(Settings.NodeProjectNumber);
    let DeviceTypeId = 0;
    switch (DeviceType) {
        case "ControlCenter":
            DeviceTypeId = 0;
            break;
        case "ScanAuto":
            DeviceTypeId = 1;
            break;
        case "ScanScooter":
            DeviceTypeId = 2;
            break;
        case "Pda":
            DeviceTypeId = 3;
            break;
        case "BackOffice":
            DeviceTypeId = 4;
            break;
        case "ScanBike":
            DeviceTypeId = 5;
            break;
        case "ScanSegway":
            DeviceTypeId = 6;
            break;
        case "ScanCam":
            DeviceTypeId = 7;
            break;
        case "RouteService":
            DeviceTypeId = 8;
            break;
    }
    return "0x" +
        ProjectNumber.toString(16).padStart(4, '0') +
        CustomerNumber.toString(16).padStart(4, '0') +
        IndexNumber.toString(16).padStart(4, '0') +
        DeviceTypeId.toString(16).padStart(4, '0');
}
async function LoadPageModule({ meta }) {
    let moduleRef = null;
    try {
        const moduleHref = meta.moduleHref;
        moduleRef = Routes[moduleHref];
    }
    catch (err) {
        if (!err.message.startsWith('Failed to fetch dynamically imported module:')) {
            console.error(err);
        }
        else {
            console.warn(err);
        }
    }
    if (moduleRef == null) {
        throw new Error(`backoffice-routes.js doesn't include url "${meta.moduleHref}"`);
    }
    return moduleRef;
}
async function LoadAJAXPage(url, meta, container) {
    AEngine.log(`Loading Route ${meta.moduleHref}`);
    try {
        const moduleRef = await LoadPageModule({ meta }); // throws error
        if (!routeService.isLoginPage(meta)) {
            await validateUserPermission({ url, meta }); // throws error
        }
        const html = await FetchHtmlContent({ meta, moduleRef });
        await InjectHtmlToContainer(container, html);
        await RunScriptsInContainer(container);
        await RunPolicies();
        const instance = new moduleRef.APage();
        PageScript = instance;
        script = instance;
        if (PageScript.init) {
            Loading.waitForPromises(Promise.resolve().then(_ => PageScript.init())
                .then(_ => Events.tryInvoke(EVENTS.PAGE_INITIALIZED))).catch(AError.handle).finally(() => {
                const $loadingIndicators = $('.loading-page-init');
                $loadingIndicators.fadeOut(300);
                sleep(300).then(() => $loadingIndicators.remove());
            });
        }
        else {
            Events.tryInvoke(EVENTS.PAGE_INITIALIZED);
        }
    }
    catch (err) {
        console.error(err);
        InjectHtmlToContainer(container, '');
        routeService.navigateTo(ROUTES.Home, { setBrowserUrl: true });
        return; //routeService.navigateTo(redirectUponError)
    }
}
async function validateUserPermission({ url, meta }) {
    const menuitem = meta.menuItem;
    if (menuitem === undefined) {
        throw new Error(`Page Not Found "${meta.moduleHref}"`);
    }
    const required = [menuitem.id_mr];
    if (menuitem.parent && menuitem.parent.id_mr)
        required.push(menuitem.parent.id_mr);
    if (!permissionService.hasPermission(required)) {
        const message = await Loading.waitForPromises(Translate.get('You are not permitted to view this page!'));
        await new Promise((resolve, reject) => {
            const events = Alerts.show({ title: ALERT_TITLES.Warning, content: message });
            events.on(ALERT_STATUS.ON_MODAL_CLOSED, () => reject(new Error(`No Permissions For Page "${url.hash}"`)));
        });
        return false;
    }
    return true;
}
async function FetchAJAXPageInternal(page) {
    const responseText = await AEngine.fetch(page);
    return responseText;
}
async function FetchHtmlContent({ meta, moduleRef }) {
    return (moduleRef.render != null) ? await Promise.resolve().then(async () => {
        const htmlToTranslate = moduleRef.render().trim();
        let output = htmlToTranslate;
        try {
            // TODO: Maybe cache translated dom
            output = await requestService.translateDom(htmlToTranslate);
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            return (moduleRef.css != null) ? moduleRef.css() + output : output;
        }
    }) : await FetchAJAXPageInternal(meta.moduleHref);
}
async function InjectHtmlToContainer(container, html) {
    container.innerHTML = html;
    return true;
}
async function RunScriptsInContainer(container) {
    let ScriptItems = container.querySelectorAll(`script:not([type="module"])`);
    if (ScriptItems.length) {
        throw new Error(`RunInscriptsInContainer is deprecated!`);
    }
}
async function RunPolicies() {
    const policyService = AEngine.get(APolicyService);
    const policiesResult = await policyService.run();
    if (!policiesResult) {
        console.warn('One or more policies failed!');
    }
}
// TODO: Move logic elsewhere
async function destroyDeskControlIfNeeded() {
    if (!PageScript)
        return;
    if (PageScript.stopVerificationSession) {
        await PageScript.stopVerificationSession();
        return;
    }
    if (PageScript.DeskControl) {
        const { CVSProcess, svo } = PageScript.DeskControl;
        if (CVSProcess) {
            await (CVSProcess.readyToFollowUp ? CVSProcess.stopASync() : Promise.resolve(1));
        }
        if (svo) {
            svo.destroyListeners();
        }
    }
}
export async function LoadPage(url, meta) {
    if (PageScript) {
        await destroyDeskControlIfNeeded();
        await eventService.tryInvoke(EVENTS.DESTRUCT);
        await eventService.tryInvoke(EVENTS.DESTRUCT_DONE);
        PageScript = null;
        script = null;
    }
    return LoadAJAXPage(url, meta, $('#AjaxContent').get(0));
}
export function AfterLogin() {
    return Promise.all([
        Translate.prepare([
            'All', 'None', 'Unknown', 'System',
            'Area', 'Zone', 'Parking Space',
            'Year', 'Week', 'Day', 'Hour',
            'View all', 'View less', 'Show History', 'History',
            'Device', 'User', 'Status', 'last change',
            'Copy Text', 'Show In Popup Window', 'Show History', 'Show on map',
            'Error', 'Detections', 'parking spaces',
            'Session', 'Disconnected'
        ]),
    ]).then(_ => {
        // TODO: Remove temp Loading.reset() code and find out why loading icon doesnt go away
        Loading.reset();
        eventService.tryInvoke(EVENTS.PREFETCH);
        if (Notification.permission !== 'granted') {
            Notification.requestPermission();
        }
        return true;
    }).catch(AError.handle);
}
export function EnableItem(Item, options) {
    $("html").css({ 'cursor': 'default' });
    Item.prop('disabled', false);
    Item.trigger('aci-event-enabled', [options || {}]);
}
export function DisableItem(Item, options) {
    Item.prop('disabled', true);
    Item.trigger('aci-event-disabled', [options || {}]);
}
export function HandleStateStream(State) {
    stateService.handleStateStream(State);
}
function HandleSocketError(ErrorCode, ErrorText) {
    const err = new Error(`Unexpected Error Code=${ErrorCode} Error=${ErrorText}`);
    console.error(err);
    HandleStateStream({ Status: "Disconnected", StatusString: "ControlCenter Disconnected" });
}
function HandleComState(State) {
    // TODO: Find out if this is still needed!
    AEngine.log(`ComState->${State}`);
    switch (State) {
        case "SessionStarted":
        case "SessionContinued":
            break;
        default:
            break;
    }
}
function HandleExit() {
    Loading.reset();
    $(".statusbar span").html("Stopped");
    let $container = $('#AjaxContent');
    $container.html(/*html*/ `
    <div style="position:relative;width:100%;height:100%;padding:2.5vw;">
      <button id="ReloadButton" class="btn btn-primary" onclick="location.reload(true);">Reload</button>
    </div>
  `);
}
function HandleConfig(ConfigData) {
    Config = Object.assign({}, ConfigData);
    const foundConfig = Config.BackOfficeConfig;
    if (foundConfig) {
        Config.BackOfficeConfig = mergeDeep({}, AConfig.DEFAULT_CONFIG, foundConfig);
    }
    else {
        Config.BackOfficeConfig = AConfig.DEFAULT_CONFIG;
    }
    Config.ClientBuildInfo = AEngine.get(AJsonService).getFromCache({ url: JSON_FILES.BUILD_INFO });
    eventService.tryInvoke(EVENTS.CONFIG_LOADED, Config);
}
function UpdateClientName() {
    const clientName = AConfig.get('general.overrideName') ? AConfig.get('general.name') : Config.Customer;
    $('head title').text(`${requestService['client'].NodeType} | ${clientName}`);
    $('.clientname').text(clientName);
    $('.clientname').closest('li').removeClass('hidden');
}
export function createEventHandlers() {
    $(window).on('resize', () => {
        eventService.tryInvoke(EVENTS.CONTENT_RESIZE, { caller: 'window' });
    });
    $(window).on('fullscreenchange', () => {
        eventService.tryInvoke(EVENTS.TOGGLE_FULLSCREEN, checkIfFullScreen());
    });
    eventService.hardwire(EVENTS.STATE_CHANGED, async () => {
        const { state, prevState } = stateService;
        let $statusbar = $('.statusbar');
        let statusString = await Translate.get(state.StatusString.length > state.Status.length ? state.StatusString : state.Status);
        $statusbar.find('.fa-circle').toggleClass('fa-beat', state.Status === 'LoggedIn');
        $statusbar.attr('class', `statusbar ${state.Status}`);
        $statusbar.find('span').html(`${statusString}`);
        if (['InvalidLogin', 'Disconnected'].includes(prevState.Status) && state.Status == 'Connected') {
            Loading.reset();
            // Show Login page
            routeService.reload();
        }
    });
    eventService.hardwire(EVENTS.ROUTE_CHANGED, ([url, meta]) => {
        LoadPage(url, meta).then(_ => {
            $('body').toggleClass('login-page-active', routeService.isLoginPage(meta));
        }).catch(AError.handle);
    });
    eventService.hardwire(`status->Connected`, () => { });
    eventService.hardwire(EVENTS.API_READY, () => {
        const buildInfo = jsonService.getFromCache({ url: JSON_FILES.BUILD_INFO });
        requestService.send('VersionStream', buildInfo);
        AEngine.log(`Sending [%cVersionStream%n]:`, buildInfo);
    });
    eventService.hardwire(`Config`, (Data) => {
        return new Promise((resolve, reject) => {
            try {
                HandleConfig(Data);
                // @ts-ignore
                resolve();
            }
            catch (err) {
                reject(err);
            }
        });
    });
    eventService.hardwire(`ConfigLoaded`, (Config) => eventService.tryInvoke('UpdateClientName'));
    eventService.hardwire(`AuthenticatorSyncRequest`, (Data) => {
        globalThis.QRImageData = Data["QRImage"];
        // console.log("load authenticator page")
        // LoadPage("/authenticator.html");
        routeService.requireAuthenticator();
    });
    eventService.hardwire(EVENTS.STATE_STREAM, (Data) => {
        const name = `status->${Data.Status}`;
        AEngine.log(`status->%c${Data.Status}%r!`);
        eventService.tryInvoke(name, Data);
        HandleStateStream(Data);
    });
    eventService.hardwire(`UserInfoStream`, () => { });
    eventService.hardwire(`ServerStatsStream`, (Data) => {
        globalThis.ServerStats = Data;
    });
    eventService.hardwire(EVENTS.SESSION_CHANGE_STREAM, (Data) => {
        let found = false;
        for (let i = 0; i < Sessions.length; i++) {
            if (Sessions[i].NodeName == Data.NodeName) {
                Sessions[i] = Data;
                found = true;
                break;
            }
        }
        if (!found) {
            Sessions.push(Data);
        }
        eventService.tryInvoke('SessionsChanged', Sessions);
    });
    eventService.hardwire(`UpdateClientName`, () => UpdateClientName());
    eventService.hardwire(`LogStream`, (Data) => {
        switch (Data.Level) {
            case "Info":
                console.log(Data.Message);
                break;
            case "Warning":
                console.warn(Data.Message);
                break;
            case "Error":
            default:
                AError.handle(new Error(Data.Message));
                break;
        }
    });
    eventService.hardwire(`SessionsInfo`, (Data) => {
        // console.warn('SessionsInfo')
        if (Data.length > 0) {
            Sessions = Data.filter(({ ComState }) => ComState !== 'SessionExpired');
            // console.log('Replacing All...', Data)
        }
        eventService.tryInvoke('SessionsChanged', Sessions);
    });
    eventService.hardwire(`LanguageResponse`, (Data) => {
        Loading.waitForPromises(Object.keys(Data).map((key) => {
            if (Data[key] !== undefined && Data[key][Language] !== undefined) {
                return Translate.insert(key, Data[key][Language].Value);
            }
            return Promise.resolve();
        }));
        console.log('Setting globalThis.Translations = ', Data);
        globalThis.Translations = Data;
    });
    eventService.hardwire(`QueryResponse`, (Data) => {
        eventService.tryInvoke(`query->${Data.Name}`, Data);
        // if (eventService.hasRoute(name)) {
        //   if (Data.Success) {
        //     eventService.tryInvoke(name, Data)
        //   } else {
        //     console.warn(`error`, Data)
        //     eventService.tryInvoke(name, new Error(Data.Error))
        //   }
        // } else {
        //   const msg = `Received ${name} but it isn't linked to any function!`
        //   console.warn(new Error(msg))
        // }
    });
    eventService.hardwire(`ApiDownloadResponse`, (Data) => {
        eventService.tryInvoke(`ApiDownloadResponse->${Data.Name}`, Data);
    });
    eventService.hardwire(`ApiDownloadRequest`, (Data) => {
        eventService.tryInvoke(`ApiDownloadRequest->${Data.Name}`, Data);
    });
    eventService.hardwire(`ApiAccessTokenResponse`, (Data) => {
        eventService.tryInvoke(`ApiAccessTokenResponse->${Data.Name}`, Data);
    });
    // OpenApiJsonRequest / OpenApiJsonResponse
    eventService.hardwire(`OpenApiJsonResponse`, (Data) => {
        globalThis.apiJson = Data;
    });
    eventService.hardwire(`TranslationResponse`, (Data) => {
        eventService.tryInvoke(`TranslationResponse->${Data.Name}`, Data.Translations);
    });
    eventService.hardwire(`query->Error`, (name, err) => {
        console.warn(`query->Error received:`, name);
        console.warn(err);
        AError.handle(err);
    });
    eventService.hardwire(EVENTS.DESTRUCT, () => {
        if (!PageScript) {
            return;
        }
        if (PageScript.map) {
            purgatoryService.closeInfoWindow();
            Object.keys(mapHelperService.cache).map((scale) => {
                const collection = mapHelperService.cache[scale];
                // Keeping the click event listeners for cached scales
                mapHelperService.unload(collection, UNLOAD_OPTIONS.None);
            });
            mapHelperService.destroy(mapHelperService.fetchMarkers());
        }
    });
    eventService.hardwire(EVENTS.DESTRUCT_DONE, () => {
        eventService.clear();
    });
}
function HandleMsg(Msg) {
    eventService.tryInvoke(Msg.Type, Msg.Data);
}
function handleLanguage() {
    const LanguageCodes = jsonService.getFromCache({ url: AJsonService.JSON_FILES.LANGUAGE });
    const allLanguageDivs = [];
    const $searchInput = $(`
    <input id="SearchLanguage" class="form-input" type="text" placeholder="">
  `);
    $searchInput.on('keyup', e => {
        const search = ($searchInput.val() || '').toString().toLowerCase();
        if (search.length === 0) {
            $searchInput.addClass('input-empty');
            allLanguageDivs.map($lang => $lang.show());
            return;
        }
        else {
            $searchInput.removeClass('input-empty');
        }
        const visibleLanguages = allLanguageDivs.filter($lang => {
            const visible = ($lang.data('language').includes(search));
            if (visible) {
                $lang.show();
            }
            else {
                $lang.hide();
            }
            return visible;
        });
        if (e.which == 13) {
            const firstLanguageInList = visibleLanguages.pop();
            if (firstLanguageInList !== undefined) {
                firstLanguageInList.find('input[type=radio]').trigger('click');
            }
        }
    });
    $('#LanguageFrame').append($searchInput);
    $searchInput.after(/*html*/ `<i id="SearchIcon" class="fa-regular fa-magnifying-glass no-select"></i>`);
    $searchInput.trigger('keyup');
    const topLanguagesKV = [
        { key: 'en', value: LanguageCodes['en'] },
        { key: 'nl', value: LanguageCodes['nl'] },
        { key: 'fr', value: LanguageCodes['fr'] },
        { spacer: true }
    ];
    const languages = [].concat(topLanguagesKV);
    const insertedLangs = [];
    $.each(LanguageCodes, function (key, value) { languages.push({ key, value }); });
    languages.map(({ key, value, spacer }) => {
        if (insertedLangs.indexOf(key) !== -1) {
            return;
        }
        insertedLangs.push(key);
        if (!spacer) {
            const attr = Language == key ? 'checked="checked"' : '';
            const $lang = $(/*html*/ `<label class="form-radio"><input type="radio" name="Language" value="${key}" ${attr}><i class="form-icon"></i> ${value}</label>`);
            $lang.data('language', value.toLowerCase());
            allLanguageDivs.push($lang);
            $("#LanguageFrame").append($lang);
        }
        else {
            $("#LanguageFrame").append(/*html*/ `
        <div class="column col-12">
          <div class="divider div-sm text-center" data-content=""></div>
        </div>
      `);
        }
    });
    $("#LanguageFrame input[type=radio]").on('click', function (e) {
        SetCookie("Language", $(this).val(), 366 * 10);
        CCCClient.Enabled = false;
        location.reload();
    });
}
// Printing background gradient fix
function createPrintHandlers() {
    window.addEventListener("beforeprint", function (ev) {
        $('body').addClass('printing');
    });
    window.addEventListener("afterprint", function (ev) {
        $('body').removeClass('printing');
    });
}
function initHandleStream() {
    return HandleStateStream({ Status: "Disconnected", StatusString: "ControlCenter Disconnected" });
}
async function genSettings() {
    let result = new ACCCClientSettings();
    await result.load();
    result.HandleMsg = HandleMsg;
    result.HandleExit = HandleExit;
    result.HandleComState = HandleComState;
    result.CustomAlert = async (message) => {
        Alerts.show({
            title: ALERT_TITLES.Info,
            content: message
        });
    };
    result.CustomConfirm = async (message, onConfirm, onCancel) => {
        const events = Alerts.show({
            title: ALERT_TITLES.Warning,
            buttons: ALERT_BUTTONS.yesNo,
            content: message.replace('\r\n', '<br>').replace('\n', '<br>'),
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, () => onConfirm());
        events.on(ALERT_STATUS.ON_ACTION_CANCEL, () => {
            if (onCancel) {
                onCancel();
            }
        });
    };
    return result;
}
function initInfoWindowAccordions() {
    $(document).on('click', (e) => {
        const $clicked = $(e.target);
        const $rkey = $clicked.closest('[rkey]');
        const $table = $rkey.closest('table');
        if ($rkey.length && $table.length) {
            const $children = $table.find(`[pkey="${$rkey.attr('rkey')}"]`);
            $children.toggle();
            const isVisible = $children.eq(0).is(':visible');
            $rkey.find('.icon-arrow-down').toggleClass('rotated-180', isVisible);
        }
    });
}
export async function prerun() {
}
export async function run() {
    const jsonService = AEngine.get(AJsonService);
    Settings = await genSettings();
    createPrintHandlers();
    initHandleStream();
    initInfoWindowAccordions();
    await jsonService.fetchAll();
    await menuService.manualInit({ translate: false });
    handleLanguage();
    Events.tryInvoke(EVENTS.JSONS_LOADED);
    globalThis.Gps = new AGpsDevice({});
}

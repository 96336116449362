import { AInputDate, AInputTime, AIsDate, formatStringToPascal, isValidJson } from "../../utils/tools.js";
import { AError } from "../../classes/AError.js";
import { AFormMapper, combine } from "./AFormMapper.js";
import { escapeHtml } from "../../utils/json.js";
import { AIdAllocator } from "../allocator/AIdAllocator.js";
export class AForm {
    static async genForm(fields, opt) {
        const htmlObj = {};
        await Promise.all(fields.map(async (field) => {
            if (field.id === undefined) {
                field.id = this.idAllocator.getNextHexId({ prefix: `${field.type}` });
            }
            if (field.label === undefined) {
                field.label = (opt.translate) ? field.id : formatStringToPascal(field.id);
            }
            if (!AFormMapper.formGeneratorMap.hasOwnProperty(field.type)) {
                console.error(`formGeneratorMap Doesn't contain key=${field.type}`);
                htmlObj[field.id] = '';
            }
            else {
                const formGroupHtml = await AFormMapper.formGeneratorMap[field.type](field);
                const wrappedHtml = (opt?.wrapInColumns) ? ( /*html*/`<div contains="${field.id ?? ''}" class="column ${field.width ?? 'col-12'}">${formGroupHtml}</div>`) : formGroupHtml;
                htmlObj[field.id] = wrappedHtml;
            }
        }));
        const html = await AForm.genFormModular({
            ids: fields.map(f => f.id),
            html: htmlObj,
            translate: opt?.translate ?? false,
            wrapInColumns: opt?.wrapInColumns ?? false,
            alignTop: opt?.alignTop ?? false,
            classList: opt?.classList ?? ''
        });
        return $(html);
    }
    static async genFormModular(opt) {
        const innerHtml = opt.ids.map(key => opt.html[key]).join('');
        const alignCls = opt.alignTop === true ? '' : 'aci-align-inputs-bottom';
        const html = ( /*html*/`
      <form class="${opt.classList}" onsubmit="return false;">
        ${(opt.wrapInColumns === true) ? `<div class="columns ${alignCls}">${innerHtml}</div>` : innerHtml}
      </form>
    `);
        return (opt.translate === false) ? html : await requestService.translateDom(html);
    }
    /**
     * @deprecated
     * Moved to AFormInstance
     */
    static async injectFormData($form, opt) {
        const { formData, formInputs, triggerChange } = combine({
            formInputs: [],
            formData: {},
            triggerChange: true
        }, opt);
        const promises = $form.find('[name]').toArray().map(inp => $(inp)).map(async ($inp) => {
            const key = $inp.attr('name');
            const type = $inp.attr('aci-type');
            const hasData = formData.hasOwnProperty(key);
            const value = formData[key];
            switch (type) {
                case 'button':
                case 'text':
                    if (hasData) {
                        $inp.val(value);
                    }
                    break;
                case 'textarea':
                case 'number':
                    if (hasData) {
                        $inp.val(value);
                    }
                    break;
                case 'checkbox':
                    if (hasData) {
                        $inp.prop('checked', value);
                    }
                    break;
                case 'time':
                    if (hasData) {
                        $inp.val(value);
                    }
                    break;
                case 'date':
                    if (hasData) {
                        $inp.val(AInputDate(value ?? new Date()));
                    }
                    break;
                case 'select':
                    if (hasData) {
                        $inp.val(value);
                        if ($inp.val() === null) {
                            $inp.val($inp.find('option:first-child').val());
                        }
                    }
                    break;
                case 'multiselect':
                    // const inputConf = <AFormInputMultiSelect|undefined>formInputs.find(conf => conf.type === 'multiselect' && conf.id === key)
                    // await filterService.fillDropDownTemplate($inp, inputConf?.options || [])
                    // break;
                    throw new Error(`Not Implemented Yet!`);
                case 'treedropdown':
                    // const inputConfTree = <AFormInputTreeDropdown|undefined>formInputs.find(conf => conf.type === 'treedropdown' && conf.id === key)
                    // const dd = await generateTreeDropdown($inp, inputConfTree!.options)
                    // dd.context.useIndices = false
                    // if (value) {
                    //   dd.context.restoreState(value)
                    // }
                    // break;
                    throw new Error(`Not Implemented Yet!`);
                case 'duration':
                    if (hasData) {
                        // console.log('duration', value)
                        const [hh, mm] = (value ?? '00:00').split(':');
                        const $eles = $inp.find('input');
                        if ($eles.length > 1) {
                            $eles.eq(0).val(hh);
                            $eles.eq(1).val(mm);
                        }
                    }
                    break;
                default:
                    AError.handleSilent(`AForm.injectFormData Unexpected form input type! key=${key} aci-type=${type}`);
                    break;
            }
            if (triggerChange) {
                $inp.trigger('change');
            }
        });
        return await Loading.waitForPromises(promises);
    }
    static async initFormValidationInput($form, input, _genFormInputs) {
        const $inp = input.$input;
        const overrideHasError = input.overrideHasError ?? (($inp, hasError) => hasError);
        const onChange = input.onChange ?? ((input, formInputs) => { });
        const initFormValidationMap = {
            'number': () => {
                $inp.on('change keyup', (e) => { onChange(input, _genFormInputs); });
            },
            'text': () => {
                const { minlength, regexAllow } = input;
                if (minlength !== undefined) {
                    $inp.on('change keyup', (e) => {
                        const str = $inp.val();
                        const hasErr = (str.length || 0) < minlength;
                        const isError = overrideHasError($inp, hasErr);
                        onChange(input, _genFormInputs);
                        $inp.toggleClass('is-error', isError);
                    }).trigger('change');
                }
                if (regexAllow) {
                    $inp.on('keypress', (e) => {
                        // console.log(e.key, regexAllow.test(e.key))
                        onChange(input, _genFormInputs);
                        return regexAllow.test(e.key);
                    });
                }
            },
            'jsontext': () => {
                const { minlength, regexAllow } = input;
                if (minlength !== undefined) {
                    $inp.on('change keyup', (e) => {
                        const str = $inp.val();
                        const hasErr = (str.length || 0) < minlength && !isValidJson(str);
                        const isError = overrideHasError($inp, hasErr);
                        onChange(input, _genFormInputs);
                        $inp.toggleClass('is-error', isError);
                    }).trigger('change');
                }
                if (regexAllow) {
                    $inp.on('keypress', (e) => {
                        // console.log(e.key, regexAllow.test(e.key))
                        onChange(input, _genFormInputs);
                        return regexAllow.test(e.key);
                    });
                }
            },
            'password': () => {
                $inp.on('change keyup', (e) => { onChange(input, _genFormInputs); });
            },
            'textarea': () => {
                $inp.on('change keyup', (e) => { onChange(input, _genFormInputs); });
            },
            'button': () => {
                $inp.on('click', (e) => {
                    return input.onClick($form);
                });
            },
            'date': () => {
                $inp.on('change keyup', (e) => { onChange(input, _genFormInputs); });
            },
            'time': () => {
                $inp.on('change keyup', (e) => { onChange(input, _genFormInputs); });
            },
            'duration': () => {
                const $inpArr = $inp.find('input').toArray().map(inp => $(inp));
                $inpArr.map(($inp, i) => {
                    $inp.on('change keyup', (e) => {
                        $inp.val($inp.val().padStart(2, '0'));
                        onChange(input, _genFormInputs);
                    });
                });
            },
            'checkbox': () => {
                $inp.on('change', (e) => {
                    onChange(input, _genFormInputs);
                });
            },
            'color': () => {
                $inp.on('change', (e) => {
                    onChange(input, _genFormInputs);
                });
            },
            'select': () => {
                $inp.on('change', (e) => {
                    const v = ($inp.val() || '');
                    const hasErr = (input.disallowNone === true) ? !(v && v.length && v !== 'null') : false;
                    const isError = overrideHasError($inp, hasErr);
                    onChange(input, _genFormInputs);
                    $inp.toggleClass('is-error', isError);
                });
            },
            'multiselect': () => {
                $inp.on('change', (e) => {
                    const dd = $inp.data('DropDown');
                    const disabled = dd.isDisabled();
                    const validated = dd.validate();
                    const hasErr = !disabled && !validated;
                    // if (input.disallowNone) {  }
                    const isError = overrideHasError($inp, hasErr);
                    onChange(input, _genFormInputs);
                    $inp.toggleClass('is-error', isError);
                });
            },
            'treedropdown': () => {
            },
            'rangeslider': () => {
                throw new Error(`Not Implemented Yet!`);
            },
            'seperator': () => {
            },
        };
        const initFunc = initFormValidationMap[input.type];
        return Promise.resolve().then(() => initFunc());
    }
    /**
     * @deprecated
     * Moved to AFormInstance
     */
    static async initFormValidation($form, formInputs) {
        const genFormInputsObj = {};
        const genFormInputs = formInputs.map((formInput) => {
            formInput.$input = $form.find(`[name="${formInput.id}"]`);
            formInput.toggle = (visible) => {
                let $inputWrapper = formInput.$input.closest(`[contains="${formInput.id}"]`);
                $inputWrapper = $inputWrapper.length ? $inputWrapper : formInput.$input.closest(`.form-group`);
                $inputWrapper.toggleClass('hidden', !visible);
            };
            formInput.setActive = (enabled) => {
                formInput.$input.prop('disabled', !enabled);
                formInput.$input?.toggleClass('disabled', !enabled);
                if (formInput.type === 'treedropdown') {
                    let dd = formInput.$input?.data('DropDown');
                    console.log('dd', dd);
                    dd.updateState();
                }
            };
            genFormInputsObj[formInput.id] = formInput;
            return formInput;
        });
        for (let input of genFormInputs) {
            const onChangeMap = {
                'number': () => 'change',
                'text': () => 'change keyup keypress',
                'jsontext': () => 'change keyup keypress',
                'password': () => 'change',
                'textarea': () => 'change',
                'button': () => 'click',
                'date': () => 'change',
                'time': () => 'change',
                'duration': () => 'change keyup',
                'checkbox': () => 'change',
                'color': () => 'change',
                'select': () => 'change',
                'multiselect': () => 'change',
                'treedropdown': () => 'change',
                'rangeslider': () => 'change',
                'seperator': () => undefined,
            };
            // const $inp = input.$input
            // const $inp = $form.find(`#${input.id}`) as JQuery
            // const overrideDisabled = input.overrideDisabled ?? (($inp, inputs) => (false))
            // const overrideHasError = input.overrideHasError ?? (($inp: JQuery, hasError: boolean) => hasError)
            // const onChange = input.onChange ?? ((input: AFormInput<T>, formInputs: AFormInputGenerated<T>[]) => {})
            // if (input.disabled === true) {
            //   $inp.prop('disabled', true)
            // }
            // const minlength = (input as AFormInputText).minlength
            // if (minlength !== undefined) {
            //   $inp.on('change keyup', (e) => {
            //     const str = (<string>$inp.val()!)
            //     const hasErr = (str.length || 0) < minlength!
            //     const isError = overrideHasError($inp, hasErr)
            //     onChange(input, genFormInputs)
            //     $inp.toggleClass('is-error', isError)
            //   }).trigger('change')
            // }
            // if (input.type === 'text') {
            //   const { regexAllow } = input
            //   if (regexAllow) {
            //     $inp.on('keypress', (e) => {
            //       // console.log(e.key, regexAllow.test(e.key))
            //       // @ts-ignore
            //       onChange(input, genFormInputs)
            //       return regexAllow.test(e.key)
            //     })
            //   }
            // }
            // if (input.type === 'jsontext') {
            //   $inp.on('change', () => {
            //     genFormInputs.map(input => {
            //       if (input.isDisabled) {
            //         const disabled = input.isDisabled({
            //           $inp,
            //           formInputs: genFormInputs,
            //           formInputsMap: genFormInputsObj as {[key in keyof T]: AFormInputGenerated<T>},
            //           formData: this.extractFormData($form, {ignoreWildcards: true}) as T
            //         })
            //         input.$input.prop('disabled', disabled)
            //         input.$input.toggleClass('disabled', disabled)
            //       }
            //       const isError = overrideHasError($inp, false)
            //       onChange(input, genFormInputs)
            //       $inp.toggleClass('is-error', isError)
            //     })
            //     onChange(input, genFormInputs)
            //   })
            // }
            // if (input.type === 'select') {
            //   $inp.on('change', (e) => {
            //     const v =  ($inp.val()! || '') as string
            //     const hasErr = ((input as AFormInputSelect).disallowNone === true) ? !(v && v.length && v !== 'null') : false
            //     const isError = overrideHasError($inp, hasErr)
            //     // @ts-ignore
            //     onChange(input, genFormInputs)
            //     $inp.toggleClass('is-error', isError)
            //     // (v && v.length && v !== 'null')$mustFollowUp.prop('checked')
            //   })
            // }
            // if (input.type === 'multiselect') {
            //   $inp.on('change', (e) => {
            //     const dd = $inp.data('DropDown') as ADropDown
            //     const disabled = dd.isDisabled()
            //     const validated = dd.validate()
            //     const hasErr = !disabled && !validated
            //     // if (input.disallowNone) {  }
            //     const isError = overrideHasError($inp, hasErr)
            //     onChange(input, genFormInputs)
            //     $inp.toggleClass('is-error', isError)
            //   })
            // }
            // if (input.type === 'checkbox') {
            //   $inp.on('change', (e) => {
            //     genFormInputs.map(input => {
            //       if (input.isDisabled) {
            //         const disabled = input.isDisabled({
            //           $inp,
            //           formInputs: genFormInputs,
            //           formInputsMap: genFormInputsObj as {[key in keyof T]: AFormInputGenerated<T>},
            //           formData: this.extractFormData($form, {ignoreWildcards: true}) as T
            //         })
            //         input.$input.prop('disabled', disabled)
            //         input.$input.toggleClass('disabled', disabled)
            //       }
            //     })
            //     onChange(input, genFormInputs)
            //   })
            // }
            // if (input.type === 'duration') {
            //   const $inpArr = $inp.find('input').toArray().map(inp => $(inp))
            //   $inpArr.map(($inp, i) => {
            //     $inp.on('change keyup', (e) => {
            //       $inp.val((<string>$inp.val()!).padStart(2, '0'))
            //       onChange(input, genFormInputs)
            //     })
            //   })
            // }
            // if (input.type === 'button') {
            //   $inp.on('click', (e) => {
            //     return (input as AFormInputButton).onClick($form)
            //   })
            // }
            await AForm.initFormValidationInput($form, input, genFormInputs);
        }
        for (let input of genFormInputs) {
            if (input.onChange) {
                input.onChange(input, genFormInputs);
            }
        }
    }
    static convertInputToKeyValue($input, opt) {
        const key = $input.attr('name');
        // const type: AFormInputType|string|undefined = $input.attr('type') as string || $input.attr('aci-type') || $input.prop('tagName')?.toLowerCase()
        const type = $input.attr('aci-type') || $input.attr('type') || $input.prop('tagName')?.toLowerCase();
        if (!AFormMapper.formToValueMap.hasOwnProperty(type)) {
            throw new Error(`Unexpected aci-type for input: AFormMapper.formToValueMap[${type}]`);
        }
        const func = AFormMapper.formToValueMap[type];
        const output = func(key, $input, opt);
        if (output !== undefined) {
            return output;
        }
        AError.handleSilent(new Error(`Didn't get a valid mapped value for input! key=${key} type=${type} opt=${JSON.stringify(opt)}`));
        console.error(`Didn't get a valid mapped value for input!`, { $input, opt, key, type });
        return { key, value: $input.val() };
    }
    static extractFormData($form, opt) {
        const keyValuePairs = {};
        let $inputArr = $form.find('[name]').toArray().map(ele => $(ele));
        if (opt.ignoreInvisible) {
            $inputArr = $inputArr.filter($inp => $inp.is(':visible'));
        }
        $inputArr.map(($input) => {
            let keyValue = AForm.convertInputToKeyValue($input, opt);
            if (keyValue !== undefined) {
                let { key, value } = keyValue;
                if (opt.ignoreWildcards !== true) {
                    keyValuePairs[key] = value;
                }
                else if (value !== '%') {
                    keyValuePairs[key] = value;
                }
            }
        });
        return keyValuePairs;
    }
    static genDateSelectInput(opt) {
        const { id, label, cls, dateRangeOpt } = opt;
        const keys = Object.keys(dateRangeOpt);
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <select id="${id}" name="${id}" class="form-input ${AForm.genInputCls({ cls })}">
          ${keys.map(key => {
            const tKey = key.replace(/ */, ' ');
            return ( /*html*/`
              <option value="${key}">${tKey}</option>
            `);
        }).join('')}
        </select>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genNumberInput(opt) {
        const { id, label, cls, step, type, min, max, value } = opt;
        const attrs = [
            `aci-type="${type}"`,
            step !== undefined ? `step="${step}"` : '',
            min !== undefined ? `min="${min}"` : '',
            max !== undefined ? `max="${max}"` : '',
            value !== undefined ? `value="${value}"` : '',
        ].join(' ');
        const placeholder = (0).toFixed((step?.length ?? 3) - 1);
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <input class="form-input ${AForm.genInputCls({ cls })}" type="number" id="${id}" name="${id}" placeholder="${placeholder}" ${attrs}>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genCheckboxInput(opt) {
        let { id, label, cls, value, type, hideLabel } = opt;
        const attrs = [
            `aci-type="${type}"`,
            value === true ? `checked="checked"` : ''
        ].join(' ');
        if (typeof cls === 'string' ? cls.includes('hidden') : cls?.label?.includes('hidden')) {
            hideLabel = true;
        }
        return ( /*html*/`
      <div class="form-group">
        <label class="form-switch">
          <input id="${id}" name="${id}" type="checkbox" ${attrs}>
          <i class="form-icon ${AForm.genInputCls({ cls })}"></i> ${hideLabel !== true ? `${label ?? id}` : ''}
        </label>
      </div>
    `);
    }
    static genDateInput(opt) {
        const { id, label, cls, value, type } = opt;
        let v = '';
        if (value == null) {
            v = AInputDate(new Date());
        }
        else if (Object.prototype.toString.call(value) === '[object Date]') {
            v = AInputDate(value);
        }
        else if (typeof value === 'string' && AIsDate(value)) {
            v = AInputDate(new Date(value));
        }
        else {
            v = value;
        }
        const attrs = [
            `aci-type="${type}"`,
            v !== undefined ? `value=${v}` : '',
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <input class="form-input ${AForm.genInputCls({ cls })}" type="date" id="${id}" name="${id}" ${attrs}>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genTimeInput(opt) {
        const { id, label, cls, value, type } = opt;
        let v = '';
        if (value === null) {
            v = AInputTime(new Date());
        }
        else if (Object.prototype.toString.call(value) === '[object Date]') {
            v = AInputTime(value);
        }
        else if (typeof value === 'string' && AIsDate(value)) {
            v = AInputTime(new Date(value));
        }
        else {
            v = value;
        }
        const attrs = [
            `aci-type="${type}"`,
            v !== undefined ? `value=${v}` : '',
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <input class="form-input ${AForm.genInputCls({ cls })}" type="time" id="${id}" name="${id}" ${attrs}>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genDurationInput(opt) {
        const { id, label, cls, value, type } = opt;
        const attrs = [
            `aci-type="${type}"`
        ].join(' ');
        const [hh, mm] = (value ?? '00:00:00').split(':');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <div id="${id}" name="${id}" class="input-group group-glue-inputs" ${attrs}>
          <input class="form-input form-inline ${AForm.genInputCls({ cls })}" type="number" id="${id}-hh" value="${hh}" min="0" maxlength="2">
          <input class="form-input form-inline ${AForm.genInputCls({ cls })}" type="number" id="${id}-mm" value="${mm}" min="0" max="59" maxlength="2">
        </div>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genTextInput(opt) {
        const { id, label, cls, minlength, maxlength, value, isPassword, type } = opt;
        const attrs = [
            `aci-type="${type}"`,
            `type="${isPassword ? 'password' : 'text'}"`,
            minlength !== undefined ? `minlength="${minlength}"` : '',
            maxlength !== undefined ? `maxlength="${maxlength ?? 64}"` : '',
            value !== undefined ? `value="${value}"` : '',
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <input class="form-input ${AForm.genInputCls({ cls })}" id="${id}" name="${id}" placeholder="..." ${attrs}>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genTextAreaInput(opt) {
        const { id, label, cls, minlength, maxlength, value, type, rows, autocomplete } = opt;
        // { id: string, label: string, minlength?: number, maxlength?: number, value?: string }) {
        const attrs = [
            `aci-type="${type}"`,
            minlength !== undefined ? `minlength="${minlength}"` : '',
            maxlength !== undefined ? `maxlength="${maxlength ?? 64}"` : '',
            rows !== undefined ? `rows="${rows}"` : '',
            ...(autocomplete === false ? [`autocomplete="off"`, `autocorrect="off"`, `autocapitalize="off"`, `spellcheck="false"`] : [])
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <textarea class="form-input ${AForm.genInputCls({ cls })}" type="text" id="${id}" name="${id}" placeholder="..." ${attrs}>${value || ''}</textarea>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genButton(opt) {
        const { id, cls, label, iconCls, type, hideLabel } = opt;
        const attrs = [
            `aci-type="${type}"`
        ].join(' ');
        const clsStr = AForm.genInputCls({ cls });
        const clsParts = [
            'btn',
            clsStr.indexOf('btn-') === -1 ? 'btn-primary' : null,
            clsStr
        ].filter(v => v != null).join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${hideLabel !== true ? AForm.genLabelHtml({ id, label: '', cls }) : ''}
        <button class="${clsParts}" id="${id}" name="${id}" placeholder="..." ${attrs}>
          ${iconCls ? `<i class="${iconCls}"></i>` : label ?? id}
        </button>
      </div>
    `);
    }
    static genColorInput(opt) {
        const { id, type, label, cls } = opt;
        const attrs = [
            `aci-type="${type}"`,
            opt.value !== undefined ? `value="${opt.value}"` : '',
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <input class="form-input ${AForm.genInputCls({ cls })}" type="color" id="${id}" name="${id}" ${attrs}>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    // number text textarea time checkbox select multiselect
    static genSelectInput(opt) {
        const { id, label, cls, options, type, value } = opt;
        let optArr = [];
        if (options[0] && options[0] instanceof Object) {
            optArr = options;
        }
        else {
            optArr = options.map(key => {
                return {
                    id: key,
                    text: key
                };
            });
        }
        const attrs = [
            `aci-type="${type}"`
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <select id="${id}" name="${id}" class="form-input ${AForm.genInputCls({ cls })}" ${attrs}>
          ${optArr.map(({ id, text }) => {
            const safeText = encodeURIComponent(text);
            const escapedText = escapeHtml(text);
            if (id === '') {
                return (`<option safetext="${safeText}" disabled="disabled">${escapedText}</option>`);
            }
            if (id === value) {
                return (`<option safetext="${safeText}" value="${id}" selected="selected">${escapedText}</option>`);
            }
            else {
                return (`<option safetext="${safeText}" value="${id}">${escapedText}</option>`);
            }
        }).join('')}
        </select>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genMultiSelectInput(opt) {
        const { id, label, cls, disabled, disallowNone, options } = opt;
        const attrs = [
            `aci-type="multiselect"`,
        ].join(' ');
        const clsList = [
            // disabled ? 'disabled' : null,
            disallowNone ? 'dd-disallow-none' : null,
            AForm.genInputCls({ cls }) ?? null,
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <div id="${id}" name="${id}" class="copycat noselect ${clsList}" ${attrs}>
          <span>None</span>
          <ul class="dropdown c-scroll"></ul>
        </div>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genTreeDropdownInput(opt) {
        const { id, label, cls, disabled, disallowNone, disallowAll, options } = opt;
        const attrs = [
            `aci-type="treedropdown"`,
            `maxlength="18"`,
        ].join(' ');
        const clsList = [
            // disabled ? 'disabled' : null,
            disallowNone ? 'dd-disallow-none' : null,
            disallowAll ? 'dd-disallow-all' : null,
            AForm.genInputCls({ cls }) ?? null,
        ].join(' ');
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <div id="${id}" name="${id}" class="wrapper-dropdown tree-config noselect ${clsList}" style="white-space: nowrap;" ${attrs}>
          <span>All</span>
        </div>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genRangeSliderInput(opt) {
        const { id, type, label, cls, value, min, max, unit, decimals } = opt;
        const attrs = [
            `aci-type="${type}"`,
            `type="${type}"`,
            `min="${min}"`,
            `max="${max}"`,
            value !== undefined ? `value="${value}"` : '',
        ].join(' ');
        const value1 = (value ? value.left ?? value[0] : min) ?? min;
        const value2 = (value ? value.right ?? value[1] : max) ?? max;
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        ${ /*<input class="form-input ${AForm.genInputCls({ cls })}" id="${id}" name="${id}" placeholder="..." ${attrs}>*/''}
        <tc-range-slider
          id="${id}"
          name="${id}"
          ${attrs}
          value1="${value1}"
          value2="${value2}"
          round="${decimals ?? 0}"
          moving-tooltip="true"
          moving-tooltip-distance-to-pointer="30"
          moving-tooltip-width="40"
          moving-tooltip-height="30"
          moving-tooltip-bg="#7DCCDF"
          moving-tooltip-text-color="#efefef"
          moving-tooltip-units="${unit ?? '%'}"
          slider-bg="#CBD5E1"
          slider-bg-hover="#94A3B8"
          slider-bg-fill="#009EC5" 
          slider-width="calc(100% - 0.4rem)"
          style="--opacity: 0.2; --margin-left: 0.4rem; --margin-top: 0.2rem; --margin-bottom: 0.2rem;"
          ></tc-range-slider>
      </div>
    `);
    }
    static genSeperator(opt) {
        const { id, type, cls, style } = opt;
        const classList = [
            'divider',
            'text-center',
            (cls !== undefined) ? cls : ''
        ].filter(v => v != null).join(' ');
        const attrs = [`aci-type="${type}"`].join(' ');
        return ( /*html*/`<div id="${id}" ${attrs} class="${classList}" style="${style ?? ''}" data-content=""></div>`);
    }
    static genChartTypeInput(opt) {
        const { id, label, cls } = opt;
        return ( /*html*/`
      <div class="form-group">
        ${AForm.genLabelHtml({ id, label, cls })}
        <select class="form-select ${AForm.genInputCls({ cls })}" id="${id}" name="${id}">
          <option value="column">Column Chart</option>
          <option value="bar">Bar Chart</option>
          <option value="line">Line Chart</option>
          <option value="spline">Spline Chart</option>
          <option value="area">Area Chart</option>
          <option value="areaspline">Area Spline Chart</option>
          <option value="scatter">Scatter Chart</option>
          <option value="pie">Pie Chart</option>
        </select>
        ${AForm.genHintHtml(opt)}
      </div>
    `);
    }
    static genLabelHtml(opt) {
        const { id, label, cls } = opt;
        const className = (cls !== undefined ? (typeof cls === 'string' ? cls : cls.label) : '') ?? '';
        return `<label class="form-label ${className}" for="${id}">${label ?? id}</label>`;
    }
    static genHintHtml(opt) {
        const hintCls = (opt.hint === undefined) ? 'hint-empty' : '';
        return `<p class="form-input-hint ${hintCls}">${opt.hint ?? ''}</p>`;
    }
    static genInputCls(opt) {
        const { cls } = opt;
        const className = (cls !== undefined ? (typeof cls === 'string' ? cls : cls.input) : '') ?? '';
        return className;
    }
}
AForm.idAllocator = new AIdAllocator({ padStart: 3 });
Object.assign(globalThis, { AForm });

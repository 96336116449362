const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
const ARound = (val, decimals = 0) => Math.round(val * Math.pow(10, decimals)) / Math.pow(10, decimals);
const toastMaxVisible = 3;
var toastId = 0;
var toastQueue = [];
var toastsVisible = {};
function toastHasQueue() { return toastQueue.length > 0; }
function toastLimitReached() { return $('.aci-toasts .aci-toast.active').length >= toastMaxVisible; }
export function toast(opt) {
    toastId++;
    const queueItem = Object.assign(opt ?? {}, {
        toastId,
        msg: opt?.msg ?? 'Empty Message',
        timeout: (opt.timeout === Infinity) ? (60 * 60 * 1000) /*ONE HOUR*/ : opt?.timeout ?? 3500,
        isInfinite: (opt.timeout === Infinity),
        allowClose: opt.allowClose ?? true,
        startTime: -1,
        endTime: -1
    });
    const events = {};
    queueItem.trigger = (eventType) => {
        const toCall = events[eventType] ?? [];
        toCall.map(fn => fn());
    };
    queueItem.on = (eventType, listener) => {
        if (!events.hasOwnProperty(eventType)) {
            events[eventType] = [];
        }
        events[eventType].push(listener);
        return queueItem;
    };
    const closePromise = new Promise((resolve) => queueItem.on('close', resolve));
    queueItem.close = () => close(queueItem);
    queueItem.waitForClose = () => closePromise;
    toastQueue.push(queueItem);
    if (toastHasQueue() && !toastLimitReached()) {
        _displayToast(toastQueue.shift());
    }
    return queueItem;
}
function _recalcToastPositions() {
    let offset = 0;
    Object.keys(toastsVisible).reverse().map((id, i) => {
        let t = toastsVisible[id];
        t.$toast.css('top', Math.round(offset) + 'px');
        offset += t.$toast.outerHeight() + 8;
    });
}
function _displayToast(toast) {
    if (toast === undefined)
        return;
    if ($('.aci-toasts').length === 0)
        $('body').append(`<div class="aci-toasts"></div>`);
    const cssDuration = ARound(Math.max(toast.timeout, 1) / 1000.0, 2);
    const classList = ['aci-toast', 'active', 'toast-shine', toast.isInfinite ? 'toast-infinite' : '', 'mt-1'].filter(v => v.length > 0).join(' ');
    const $underline = $(`<div class="toast-underline" style="animation-duration: ${cssDuration}s"></div>`);
    let $toast = $(/*HTML*/ `
    <div toast-id="${toast.toastId}" class="${classList}">
      <div class="content">
        <span class="toast-prefix">${toast.toastId}</span>
        <span class="toast-text">${toast.msg}</span>
        <a href="#" class="btn btn-clear no-select ${toast.allowClose ? '' : 'hidden'}" aria-label="Close"></a>
      </div>
    </div>
  `).prependTo($('.aci-toasts'));
    $toast.append($underline);
    toast.$toast = $toast;
    toast.startTime = Date.now();
    toast.endTime = Date.now() + toast.timeout;
    toastsVisible[toast.toastId] = toast;
    let ogTimeout = toast.timeout;
    $toast.on('mouseover', () => {
        if (toast.paused !== true) {
            toast.paused = true;
            toast.timeleft = extractToastTiming(toast).timeleft;
            $underline.css('animation-play-state', `paused`);
        }
    });
    $toast.on('mouseleave', () => {
        if (toast.paused === true) {
            toast.paused = false;
            toast.endTime = Date.now() + toast.timeleft;
            toast.startTime = toast.endTime - ogTimeout;
            $underline.css('animation-play-state', `running`);
            setToastExpireTime(toast, toast.timeleft);
        }
    });
    if (toast.allowClose === true) {
        $toast.on('click', () => destroyToast(toast, 150));
    }
    setToastExpireTime(toast, toast.timeout);
    _recalcToastPositions();
}
function extractToastTiming(toast) {
    const delta = toast.endTime - toast.startTime;
    const t = clamp((Date.now() - toast.startTime) / delta, 0, 1);
    const timeleft = toast.timeout * (1.0 - t);
    return { t, timeleft };
}
function setToastExpireTime(toast, timeout) {
    const internalId = idAllocatorService.getNextId({ prefix: 'toast-' });
    toast.internalId = internalId;
    setTimeout(() => {
        if (toastsVisible[toast.toastId]?.paused !== true && toast.internalId === internalId) {
            destroyToast(toast, 300);
        }
    }, timeout);
}
function close(toast) {
    if (!toastsVisible.hasOwnProperty(toast.toastId)) {
        return;
    }
    toast.$toast.remove();
    toast.trigger('close');
    delete toastsVisible[toast.toastId];
    if (toastHasQueue() && !toastLimitReached()) {
        _displayToast(toastQueue.shift());
    }
    else {
        _recalcToastPositions();
    }
}
function destroyToast(toast, animationSpeed) {
    if (animationSpeed === undefined) {
        return close(toast);
    }
    toast.$toast.fadeOut(animationSpeed, () => close(toast));
}
globalThis.toast = toast;
